var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"content",style:({
      'background-image': 'url(' + _vm.homeInfo.bg + ')',
      'background-size': 'cover',
      'background-repeat': 'no-repeat',
      'background-position': 'center center',
    })},[_c('div',{staticClass:"content-people"},[_c('div',{staticClass:"content-people-top"},[_c('span',[_vm._v(_vm._s(_vm.addCommas))]),_c('img',{attrs:{"src":require("@/assets/pc/gift/attach-top.png"),"alt":""}})]),_c('div',{staticClass:"content-people-bottom"})]),_c('div',{staticClass:"content-top-bg"}),_c('div',{staticClass:"content-center"},_vm._l((_vm.giftList),function(item,i){return _c('div',{key:i,staticClass:"content-center-item",style:({
          'background-image': 'url(' + item.bg + ')',
        })})}),0),_c('div',{staticClass:"content-bottom-bg"},[_c('div',{staticClass:"line"},[_vm._l((5),function(item,i){return _c('div',{key:i,staticClass:"dot",class:_vm.countReachNumDot,style:({
            left: _vm.countLeft(i),
          })})}),_c('div',{staticClass:"line-cover",class:_vm.countReachNum})],2)]),_c('div',{staticClass:"content-introduce"},_vm._l((_vm.giftList),function(item,i){return _c('div',{key:i,staticClass:"content-introduce-item",style:({
          'background-image': 'url(' + item.introduce + ')',
        })})}),0),_vm._m(0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-reward_text"},[_c('p',[_vm._v(" 特典を受け取るには、事前登録者数に応じた市役所レベルに到達している必要があります。それぞれ、5万人ではLv.2、10万人ではLv.3、20万人ではLv.4、35万人ではLv.5、50万人ではLv.7に到達するのが条件となります。事前登録者数報酬はゲーム内メールで配布されます。 ")]),_c('p',[_c('span',[_vm._v(" ※このイベントは日本地域のユーザー限定です。 ")]),_c('span',[_vm._v(" 　（特典受け取り期限：2024年1月5日まで） ")])])])}]

export { render, staticRenderFns }