var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"header-left"}),_c('div',{staticClass:"header-right",on:{"click":function($event){_vm.showMenu = true}}}),_c('div',{staticClass:"menu",class:_vm.showMenu ? 'active-menu' : 'inactive-menu'},[_c('div',{staticClass:"menu-header"},[_c('div',{staticClass:"menu-header-left"}),_c('div',{staticClass:"menu-header-right",on:{"click":function($event){_vm.showMenu = false}}})]),_c('div',{staticClass:"menu-nav"},_vm._l((_vm.navList),function(item,i){return _c('div',{key:i,class:item.name === _vm.activeNav ? 'active-nav each-nav' : 'each-nav',on:{"click":function($event){return _vm.jumpToNav(i)}}},[_c('div',{style:({
            'background-image': 'url(' + item.bg + ')',
            'background-size': 'cover',
            'background-repeat': 'no-repeat',
            'background-position': 'center center',
            width: item.width,
            height: '4rem',
          })})])}),0),_c('div',{staticClass:"menu-download"},[_c('div',{staticClass:"menu-download-apple",on:{"click":function($event){return _vm.jumpToStore('ios')}}}),_c('div',{staticClass:"menu-download-google",on:{"click":function($event){return _vm.jumpToStore('google')}}}),_c('div',{staticClass:"menu-tripartite"},[_c('div',{staticClass:"menu-tripartite-twitter",on:{"click":function($event){return _vm.jumpToStore('twitter')}}}),_c('div',{staticClass:"menu-tripartite-youtube",on:{"click":function($event){return _vm.jumpToStore('youtube')}}},[_c('div',{staticClass:"border-line"})]),_c('div',{staticClass:"menu-tripartite-line",on:{"click":function($event){return _vm.jumpToStore('line')}}})])]),_c('div',{staticClass:"menu-newline"})])])}
var staticRenderFns = []

export { render, staticRenderFns }