<template>
  <div
    class="arrow"
    :style="{
      bottom: bottomDistance,
    }"
  >
    <div class="left-line" v-if="showShortLine">
      <span v-for="(item, i) in 8" :key="i"></span>
    </div>
    <div class="center-arrow"></div>
    <div class="right-line" v-if="showShortLine">
      <span v-for="(item, i) in 8" :key="i"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "component_name",
  props: {
    bottomDistance: {
      type: String,
      default: "0rem",
    },
    showShortLine: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  created() {},

  mounted() {},

  methods: {},

  destroyed() {},
};
</script>

<style lang="scss" scoped>
@keyframes floating {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(0.5rem);
  }

  100% {
    transform: translateY(0);
  }
}
.arrow {
  width: 40.38rem;
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0%;
  left: 50%;
  margin-left: -20.19rem;
  z-index: 999;
  .left-line,
  .right-line {
    width: 17.13rem;
    height: 0.13rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    span {
      width: 1.2rem;
      height: 100%;
      background-color: #a5a5a5;
      opacity: 0.2;
    }
  }
  .center-arrow {
    width: 3rem;
    height: 2.25rem;
    background-image: url("~@/assets/pc/icon_global_pull_down.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1.5rem;
    animation: floating 1.5s infinite ease-in-out;
  }
}
</style>
