const CN = {
  label: {
    language: "简体中文",
    appoint_title: "预约游戏领取豪礼",
    email_tips: "输入邮箱可获得专属奖励",
    ios_appoint: "iOS用户立即预约",
    Public_beta: "10月iOS全服公测",
    submit: "提交",
    Reached: "已达成",
    cur_appoint: "全球已有XX人预约",
    appoint_reward: "预约奖励",
    page_title: "《暗影谜题》iOS预约站",
    appoint_now: "我要预约",
  },
  msg: {
    title: "提示",
    success: "预约成功，请等待开服通知",
    has_appoint: "已预约，请勿重复提交",
    know: "知道了",
    submit_success: "提交成功",
    email_error: "邮箱格式错误，请重新填写",
    input_email: "请输入邮箱",
  },
  gift: {
    food: "5000食物",
    wood: "5000木材",
    common_recruitment_currency: "普通招募币",
    diamond_supply_box: "钻石补给箱",
    building_acceleration: "5分钟建筑加速",
    accelerated_scientific_research: "5分钟科研加速",
    training_acceleration: "5分钟部队训练加速",
    manufacturing_acceleration: "5分钟陷阱制造加速",
    advanced_recruitment_currency: "高级招募币",
    fortifying_medicament: "强化药剂1000",
    wizard_Jenny: "3星巫师 珍妮",
    witch_Matt: "4星巫师 马特",
  },
  footer: {
    $1: "隐私协议",
    $2: "服务条款",
    $3: "川公网安备 51019002003535号",
    $4: "蜀ICP备2020036928号-1",
    $5: "Copyright @2019-2022 版权所有",
  },
};
export default CN;
