const ja = {
  label: {
    language: "日本語",
    appoint_title: "事前登録して豪華報酬をゲットしよう",
    email_tips: "メールアドレスを入力して専属報酬をゲット可能",
    ios_appoint: "今すぐ登録（iOS版）",
    Public_beta: "iOS版登場！10月より配信開始！",
    submit: "確認",
    Reached: "達成",
    cur_appoint: "事前登録者数：XX",
    appoint_reward: "事前登録報酬",
    page_title: "「ムーンライズ・領主の帰還」iOS版事前登録サイト",
    appoint_now: "今すぐ登録",
  },
  msg: {
    title: "ご注意",
    success:
      "事前登録完了！ゲームの配信開始後に、正式サービス開始のご連絡をいたします。楽しみにお待ちください。",
    has_appoint: "事前登録済み！重複の送信はご遠慮ください。",
    know: "はい",
    submit_success: "提出した",
    email_error: "メールアドレスを正しく入力してください！",
    input_email: "请输入邮箱",
  },
  gift: {
    food: "5,000食料",
    wood: "5,000木材",
    common_recruitment_currency: "初級募集コイン",
    diamond_supply_box: "ダイヤ補給箱",
    building_acceleration: "建設スピードアップ(5分間)",
    accelerated_scientific_research: "研究スピードアップ(5分間)",
    training_acceleration: "訓練スピードアップ(5分間)",
    manufacturing_acceleration: "防衛施設スピードアップ(5分間)",
    advanced_recruitment_currency: "中級募集コイン",
    fortifying_medicament: "1,000強化ポーション",
    wizard_Jenny: "★3ウィザード・ジェニー",
    witch_Matt: "★4ウィザード・マット",
  },
  footer: {
    $1: "プライバシーポリシー",
    $2: "ユーザー同意書",
    $3: "川公网安备 51019002003535号",
    $4: "蜀ICP备2020036928号-1",
    $5: "Copyright @2019-2022 版权所有",
  },
};
export default ja;
