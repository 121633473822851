import request from "./request";

/** 上报埋点数据 */
export function reportData(data, isFile) {
  return request({
    url: "/api/data",
    method: "post",
    data,
    isFile,
  });
}

/** 通过ip获取国家code */
export function reqIpInfo(data) {
  return request({
    url: "/api/common/addr",
    method: "post",
    data,
  });
}
