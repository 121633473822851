const gr = {
  label: {
    language: "Deutsch",
    appoint_title: "Holen Sie sich eine vorregistrierte Prämie",
    email_tips:
      "Gib deine E-Mail-Adresse ein und erhalte eine exklusive Prämie",
    ios_appoint: "Vorbestellung für iOS",
    Public_beta: "Offener Test für iOS startet im Oktober 2022!",
    submit: "Einreichen",
    Reached: "Erreicht",
    cur_appoint: "XX Spieler schon vorregistriert",
    appoint_reward: "Vorregistrierte Prämien",
    page_title: '"Myths of Moonrise" iOS Pre-register Website',
    appoint_now: "Jetzt vorbestellen",
  },
  msg: {
    title: "Nachricht",
    success: "Vorregistrierung erfolgreich. Bleib dran!!",
    has_appoint: "Sie haben die Vorregistrierung bereits eingereicht",
    know: "OK",
    submit_success: "Erfolgreich eingereicht",
    email_error: "Bitte geben Sie die korrekte E-Mail-Adresse ein",
    input_email: "E-mail address",
  },
  gift: {
    food: "5,000 Nahrung",
    wood: "5,000 Hölzer",
    common_recruitment_currency: "Normale Rekrutierungsmünze",
    diamond_supply_box: "Diamant-Vorratstruhe",
    building_acceleration: "5 Min.Baubeschleuniger",
    accelerated_scientific_research: "5 Min.Forschungsbeschleuniger",
    training_acceleration: "5 Min.Trainingsbeschleuniger",
    manufacturing_acceleration: "5 Min. Herstellungsbeschleuniger",
    advanced_recruitment_currency: "Fortgeschrittene Rekrutierungsmünze",
    fortifying_medicament: "1,000 Verstärker",
    wizard_Jenny: "3★ Hexenmeister - Jennie",
    witch_Matt: "4★ Hexenmeister - Matt",
  },
  footer: {
    $1: "Datenschutzvereinbarung",
    $2: "Nutzungsbedingungen",
    $3: "Sichuan Public Network Security No. 51019002003535",
    $4: "Shu ICP No. 2020036928-1",
    $5: "Copyright @2019-2022 Alle Rechte vorbehalten",
  },
};
export default gr;
