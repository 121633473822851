var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hero"},[_c('img',{staticClass:"hero-title",attrs:{"src":require("@/assets/mobile/charter_title.png"),"alt":""}}),_c('div',{staticClass:"hero-content"},[_c('div',{staticClass:"bg",style:({
        'background-image': 'url(' + _vm.heroInfoList[_vm.activeIndex].hero_img + ')',
        'background-size': 'contain',
        'background-repeat': 'no-repeat',
        'background-position': 'center center',
      })}),_c('div',{staticClass:"carousel"},_vm._l((_vm.heroInfoList),function(item,index){return _c('div',{key:index,staticClass:"carousel-item",style:({
          'background-image':
            'url(' +
            (index === _vm.activeIndex ? item.head_active : item.head_default) +
            ')',
        }),on:{"click":function($event){_vm.activeIndex = index}}})}),0),_c('img',{staticClass:"left_arrow arrow",attrs:{"src":require("@/assets/mobile/left_arrow.png"),"alt":""},on:{"click":function($event){return _vm.changeIndex('prev')}}}),_c('img',{staticClass:"right_arrow arrow",attrs:{"src":require("@/assets/mobile/right_arrow.png"),"alt":""},on:{"click":function($event){return _vm.changeIndex('next')}}})])])}
var staticRenderFns = []

export { render, staticRenderFns }