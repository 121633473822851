import Vue from "vue";
import App from "./App.vue";
import store from "./store/index";
import { Progress, Tooltip } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./style/common.scss";
import { nanoid } from "nanoid";
import "./components/msgbox/msgbox.css";
import { Dropdown, DropdownItem, DropdownMenu } from "element-ui";
import VueLazyload from "vue-lazyload";
Vue.component(Dropdown.name, Dropdown);
Vue.component(DropdownItem.name, DropdownItem);
Vue.component(DropdownMenu.name, DropdownMenu);

Vue.use(Progress);
Vue.use(Tooltip);
Vue.use(VueLazyload);
Vue.config.productionTip = false;
Vue.prototype.$LOGID = nanoid;

const vue = new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");

export default vue;
