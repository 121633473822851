import { render, staticRenderFns } from "./HeroInfoModal.vue?vue&type=template&id=7b639043&scoped=true&"
import script from "./HeroInfoModal.vue?vue&type=script&lang=js&"
export * from "./HeroInfoModal.vue?vue&type=script&lang=js&"
import style0 from "./HeroInfoModal.vue?vue&type=style&index=0&id=7b639043&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b639043",
  null
  
)

export default component.exports