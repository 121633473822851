<template>
  <div class="home">
    <div
      class="content"
      :style="{
        'background-image': 'url(' + homeInfo.bg + ')',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': 'center center',
      }"
    >
      <div
        class="content-area"
        @mousemove="countShowEachHero"
        @mouseleave="cancelShowEachHero"
      >
        <div
          v-for="(item, i) in heroInfoList"
          :key="i"
          :class="countHeroClassName(i)"
          @click="showCurrentHeroInfo(item.name)"
        >
          <img v-show="activeHeroIndex[i]" :src="item.hero_img" alt="" />
          <div
            v-show="!activeHeroIndex[i]"
            class="default"
            :style="{
              'background-image': 'url(' + item.hero_def_img + ')',
              'background-size': 'cover',
              'background-repeat': 'no-repeat',
              'background-position': 'center center',
            }"
          ></div>
          <div
            :class="`name name_${i + 1}`"
            :style="{
              'background-image': 'url(' + item.hero_name + ')',
            }"
          ></div>
        </div>
      </div>
      <div class="content-bot" v-if="showFooter"></div>
    </div>

    <HeroInfoModal ref="heroInfoModal" @closeModal="closeModal" />
  </div>
</template>

<script>
import HeroInfoModal from "@/components/HeroInfoModal";
import { trackClick } from "@/tools/trackClick.js";

export default {
  components: {
    HeroInfoModal,
  },
  name: "component_name",
  data() {
    return {
      showFooter: true,
      activeHeroIndex: [false, false, false, false, false],
    };
  },
  created() {},
  computed: {
    countHeroClassName() {
      return (i) => {
        if (this.activeHeroIndex[i]) {
          return `content-area-item info_${i + 1} info_${i + 1}_active`;
        } else {
          return `content-area-item info_${i + 1} info_${i + 1}_inactive`;
        }
      };
    },
    homeInfo() {
      return {
        bg: require("@/assets/pc/hero/hero-bg.png"),
        name: "home",
      };
    },
    heroInfoList() {
      return [
        {
          hero_img: require("@/assets/pc/hero/hero-new/1.png"),
          hero_def_img: require("@/assets/pc/hero/1_bg.png"),
          hero_name: require("@/assets/pc/hero/hero-new/xixuegui_name2.png"),
          name: "meier",
        },
        {
          hero_img: require("@/assets/pc/hero/hero-new/2.png"),
          hero_def_img: require("@/assets/pc/hero/2_bg.png"),
          hero_name: require("@/assets/pc/hero/hero-new/yishou_name.png"),
          name: "shala",
        },
        {
          hero_img: require("@/assets/pc/hero/hero-new/3.png"),
          hero_def_img: require("@/assets/pc/hero/3_bg.png"),
          hero_name: require("@/assets/pc/hero/hero-new/liemoren_name.png"),
          name: "balun",
        },
        {
          hero_img: require("@/assets/pc/hero/hero-new/4.png"),
          hero_def_img: require("@/assets/pc/hero/4_bg.png"),
          hero_name: require("@/assets/pc/hero/hero-new/nvguanjia_name.png"),
          name: "xiunv",
        },
        {
          hero_img: require("@/assets/pc/hero/hero-new/5.png"),
          hero_def_img: require("@/assets/pc/hero/5_bg.png"),
          hero_name: require("@/assets/pc/hero/hero-new/xixuegui_name.png"),
          name: "luyisi",
        },
      ];
    },
  },
  mounted() {},

  methods: {
    showCurrentHeroInfo(n) {
      return;
      this.$emit("changeRightNav", false);
      trackClick({
        behavior_tag: "Examine Role",
      });
      this.showFooter = false;
      this.$refs.heroInfoModal.showModal(n);
    },
    closeModal() {
      this.showFooter = true;
      this.$emit("changeRightNav", true);
    },
    countShowEachHero(e) {
      let leftDis = Math.floor((e.clientX / document.body.clientWidth) * 100);
      let activeIndex;
      if (this.isInRange(leftDis, 0, 16)) {
        activeIndex = 0;
      } else if (this.isInRange(leftDis, 16, 32)) {
        activeIndex = 1;
      } else if (this.isInRange(leftDis, 32, 48)) {
        activeIndex = 2;
      } else if (this.isInRange(leftDis, 48, 64)) {
        activeIndex = 3;
      } else if (this.isInRange(leftDis, 64, 90)) {
        activeIndex = 4;
      } else {
        activeIndex = 5;
      }
      this.activeHeroIndex = this.activeHeroIndex.map((item, i) => {
        item = i === activeIndex ? true : false;
        return item;
      });
    },
    cancelShowEachHero() {
      this.activeHeroIndex = this.activeHeroIndex.map((item, i) => {
        item = false;
        return item;
      });
    },
    isInRange(value, min, max) {
      return value >= min && value < max;
    },
  },

  destroyed() {},
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  .content {
    width: 100%;
    height: 100%;
    &-area {
      width: 100%;
      height: 91.2%;
      position: absolute;
      left: 0;
      // top: 8.8%;
      &-item {
        width: 15.95%;
        height: 100%;
        // border-right: 1px solid #fff;
        overflow: hidden;
        position: absolute;
        z-index: 990;
        cursor: pointer;
        &:not(:last-child) {
          &::after {
            width: 1.5px;
            height: 100%;
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            background-image: url("../../assets/pc/hero/division-line.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center center;
          }
        }
        .name {
          position: absolute;
          bottom: 0%;
          left: 50%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          z-index: 999;
        }
        .name_1 {
          width: 12.5rem;
          height: 6.63rem;
          // margin-left: -38%;
          transform: translate(-50%, 0);

          display: none;
        }
        .name_2 {
          width: 34.19rem;
          height: 15.63rem;
          // margin-left: -90%;
          transform: translate(-50%, 0);
          display: none;
        }
        .name_3 {
          width: 33.19rem;
          height: 15.79rem;
          // margin-left: -90%;
          transform: translate(-50%, 0);
          display: none;
        }
        .name_4 {
          width: 34.19rem;
          height: 15.63rem;
          transform: translate(-50%, 0);
          // margin-left: -88%;
          display: none;
        }
        .name_5 {
          width: 17.75rem;
          height: 6.63rem;
          // margin-left: -39%;
          transform: translate(-50%, 0);
          display: none;
        }
      }
      .info_1 {
        left: 0;
        position: absolute;
        top: 0;

        img {
          position: absolute;
          height: 100%;
          left: 0;
          top: 9%;
        }
        .default {
          top: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 12%;
        }
      }
      .info_1_active {
        &:hover {
          overflow: visible !important;
          z-index: 991;
          img {
            transform: scale(1.1);
            width: auto;
            z-index: 991;
            left: 0%;
            top: 10%;
            transition: transform 0.5s linear;
          }
          .name_1 {
            display: block;
          }
        }
      }
      .info_1_inactive {
        left: 0%;
        img {
          position: absolute;
          width: 100%;
          height: auto;
          left: 0;
          top: 13%;
        }
      }
      .info_2 {
        left: 15.96%;
        height: 100%;
        position: absolute;

        img {
          position: absolute;
          height: 100%;
          left: -80%;
          top: 2%;
        }
        .default {
          top: 0;
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }
      .info_2_active {
        &:hover {
          overflow: visible;
          z-index: 992;
          img {
            transform: scale(1.1);
            z-index: 992;
            transition: transform 0.5s linear;
          }
          .name_2 {
            display: block;
          }
        }
      }

      .info_2_inactive {
        left: 15.95%;
        img {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0%;
          top: -0%;
        }
      }
      .info_3 {
        left: 31.95%;
        height: 100%;
        // margin-top: 0%;
        position: absolute;

        img {
          position: absolute;
          height: 120%;
          left: -112%;
          top: -2%;
        }
        .default {
          top: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0%;
        }
      }
      .info_3_active {
        &:hover {
          overflow: visible;
          z-index: 993;
          img {
            transform: scale(1.1);
            z-index: 993;
            top: -2%;
            transition: transform 0.5s linear;
          }
          .name_3 {
            display: block;
          }
        }
      }
      .info_3_inactive {
        img {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0%;
          top: -0%;
        }
      }
      .info_4 {
        left: 47.88%;
        height: 100%;
        width: 16.2%;
        // margin-top: 0%;
        position: absolute;

        img {
          position: absolute;
          height: 100%;
          left: -60%;
          top: 0%;
        }
        .default {
          top: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0%;
        }
      }
      .info_4_active {
        &:hover {
          overflow: visible;
          z-index: 994;
          img {
            transform: scale(1.1);
            z-index: 994;
            transition: transform 0.5s linear;
          }
          .name_4 {
            display: block;
          }
        }
      }
      .info_4_inactive {
        img {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0%;
          top: -0%;
        }
      }
      .info_5 {
        left: 64.1%;
        height: 100%;
        width: 30%;
        // margin-top: 0%;
        position: absolute;

        img {
          position: absolute;
          height: 100%;
          left: -18%;
          top: 14%;
        }
        .default {
          top: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 10%;
        }
      }
      .info_5_active {
        &:hover {
          overflow: visible;
          z-index: 995;
          img {
            transform: scale(1.1);
            z-index: 995;
            left: -22%;
            top: 10%;
            transition: transform 0.5s linear;
          }
          .name_5 {
            display: block;
          }
        }
      }
      .info_5_inactive {
        width: 16.2%;
        img {
          position: absolute;
          left: 0;
          top: 14%;
        }
      }
      // background-image: url("../../assets/pc/gift/area-bt.png");
      // background-size: 100% 100%;
      // background-repeat: no-repeat;
      // background-position: center center;
    }
    &-bot {
      width: 100%;
      height: 6.13rem;
      background-image: url("../../assets/pc/hero/footer-bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 9999;
    }
  }
}
</style>
