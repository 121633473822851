import webStorage from "./setTimeStorage";
import vue from "../main";
import { reportData } from "../settings/api";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { reqIpInfo } from "../settings/api";
// 获取设备id
function getDeviceId() {
  return new Promise((resolve, reject) => {
    FingerprintJS.load().then((fp) => {
      fp.get().then((result) => {
        const visitorId = result.visitorId;
        localStorage.setItem("dv", visitorId);
        resolve();
      });
    });
  });
}
// 获取国家编码
function getCountryInfo(ip) {
  return new Promise((resolve, reject) => {
    reqIpInfo({ ip_list: [ip] })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        resolve(err);
      });
  });
}
export async function trackClick(obj) {
  const type = Object.prototype.toString.call(obj);
  const { title, submit_success, know } =
    vue.$store.state.languages[vue.$store.state.language].msg;

  var clientIP;
  try {
    // 等待获取到设备id
    if (!localStorage.getItem("dv")) {
      await getDeviceId();
    }

    clientIP = window.ip || null;
    console.log("ip=>", clientIP);
  } catch {
    clientIP = null;
  }

  if (clientIP) {
    localStorage.setItem("ip", clientIP);
    if (!localStorage.getItem("gj")) {
      let countryCode = await getCountryInfo(clientIP);
      if (
        countryCode.code === 20000 &&
        countryCode.data.addr_list.length !== 0 &&
        countryCode.data.addr_list[0] !== null
      ) {
        localStorage.setItem("gj", countryCode.data.addr_list[0].iso_code);
      }
    }
  }

  if (type === "[object Object]") {
    let formatObj = {
      ...{
        log_id: vue.$LOGID(32), //唯一id
        event_name: "log_website_appointment", //事件名
        created_at: parseInt(new Date().getTime() / 1000), // 当前时间戳（s）
        zone: `+${0 - new Date().getTimezoneOffset() / 60}`, //时区
        device_id: localStorage.getItem("dv") || "", // 设备id
        role_id: localStorage.getItem("dv") || "", //无角色id使用设备id
        game_id: +process.env.VUE_APP_PROJECT_ID, //release:26,master:6
        terminal: vue.$store.state.deviceType, // 设备类型，pc 还是  mobile,
        year: new Date().getFullYear().toString(), // 年
        month: (new Date().getMonth() + 1).toString(), //月份
        day: new Date().getDate().toString(), // 日
        country: localStorage.getItem("gj") ? localStorage.getItem("gj") : "", // 国家
        ip: localStorage.getItem("ip") ? localStorage.getItem("ip") : "", // ip
      },
      ...obj,
    };
    // console.log('基础埋点数据=>', formatObj);
    let arr = [];
    if (webStorage.getItem("localStorage", "tc")) {
      let v = webStorage.getItem("localStorage", "tc");
      if (v.constructor === Object) {
        arr = JSON.parse(v.trackClick);
      } else {
        arr = v;
      }
      arr.push(formatObj);
      let blob = new Blob([JSON.stringify(arr)], { type: "application/json" });
      let size = blob.size;
      console.log("埋点数据文件流大小=>", size);
      // 大小限制
      if (size > 1024 * 1024) {
        reportData(
          { file: blob, stream_prefix: process.env.VUE_APP_STREAM_PREFIX },
          true
        ).then((res) => {
          if (res.code === 20000) {
            console.log("上报成功=>", res);
            // vue.$store.commit("openNewMsg", {
            //   title: "提示",
            //   body: "提交成功！",
            //   autoClose: true,
            //   time: 1500,
            // });

            localStorage.removeItem("tc");
          } else {
            console.log("上报失败=>", res);
            // vue.$store.commit("openNewMsg", {
            //   title: "提示",
            //   body: `${res.code}---${res.message}`,
            //   btnLabel: ["确定"],
            //   firstCallback: function () {
            //     vue.$store.state.msg.closeMsg();
            //   },
            // });
          }
        });
      }
    } else {
      arr = [formatObj];
    }
    webStorage.setItem("localStorage", "tc", JSON.stringify(arr), 300000);
  } else {
    console.log("埋点数据格式错误");
  }
}
