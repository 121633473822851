<template>
  <div id="app">
    <!-- <TheHeader /> -->
    <Index v-if="deviceType === 'pc'"></Index>
    <Mobile v-if="deviceType !== 'pc'"></Mobile>
  </div>
</template>

<script>
import Index from "./views/PcIndex.vue";
import Mobile from "./views/MobileIndex.vue";
import TheHeader from "./components/TheHeader.vue";
import webStorage from "./tools/setTimeStorage";
import { trackClick } from "./tools/trackClick.js";
import { reportData } from "./settings/api";
import { signer } from "@/settings/crypto.js";
import { convertFormdata } from "@/tools/formdata.js";
import { json } from "body-parser";
export default {
  name: "App",
  components: { Index, Mobile, TheHeader },
  data() {
    return {
      deviceType: "",
      interval: null,
      timer: null, //全局定时器
      // residenceTimer: null, // 记录用户停留时长的定时器s
    };
  },

  provide() {
    return {
      deviceType: () => this.deviceType,
    };
  },
  created() {
    this.judgeDeviceType();
    this.setLanguage();
    // 定时上报数据，3分钟
    this.timer = setInterval(() => {
      let data = webStorage.getItem("localStorage", "tc");
      let blob;
      if (data && data.constructor === Object && data.trackClick) {
        blob = new Blob([data.trackClick], { type: "application/json" });
        this.uploadData(blob);
      } else if (data && data.constructor === Array) {
        blob = new Blob([JSON.stringify(data)], { type: "application/json" });
        this.uploadData(blob);
      }
    }, 2 * 60 * 1000);
  },

  computed: {
    language() {
      return this.$store.state.language;
    },
  },

  mounted() {
    const _this = this;
    this.setLanguage();
    //过期时间为24h，初次浏览或超过一天后刷新，记作浏览
    if (!webStorage.getItem("sessionStorage", "ll")) {
      webStorage.setItem("sessionStorage", "ll", true, 24 * 60 * 60000);
      this.$nextTick(() => {
        trackClick({
          behavior_tag: "Browse Page",
        });
      });
    }
    // 窗口尺寸变化重新判定机型
    window.onresize = () =>
      (() => {
        this.judgeDeviceType();
      })();
    let beginTime = 0; //开始时间
    let differTime = 0; //时间差
    window.onunload = async function () {
      differTime = new Date().getTime() - beginTime;
      console.log("onunload", new Date().getTime(), differTime);

      if (differTime <= 5) {
        // localStorage.removeItem("time");
        console.log("关闭");
        let data = webStorage.getItem("localStorage", "tc");
        let copyData = [];
        let copyLastValue = {};
        if (data && data.constructor === Object && data.trackClick) {
          console.log(5555, data.trackClick);
          copyLastValue = data.trackClick[0];
          copyData = [...data.trackClick];
        } else if (data && data.constructor === Array) {
          copyLastValue = data[0];
          copyData = [...data];
        }
        copyLastValue.browsing_time = localStorage.getItem("time");
        copyLastValue.behavior_tag = "Leave Page";
        copyData.push(copyLastValue);
        localStorage.setItem("aa", JSON.stringify(copyData));

        let blob;
        if (
          copyData &&
          copyData.constructor === Object &&
          copyData.trackClick
        ) {
          blob = new Blob([copyData.trackClick], { type: "application/json" });
        } else if (copyData && copyData.constructor === Array) {
          blob = new Blob([JSON.stringify(copyData)], {
            type: "application/json",
          });
        }
        let fixData = {
          file: blob,
          stream_prefix: process.env.VUE_APP_STREAM_PREFIX,
        };

        const { ts, seed, sign } = signer(fixData);
        fixData.ts = ts;
        fixData.seed = seed;
        fixData.signature = sign;
        let formData = convertFormdata(fixData);
        navigator.sendBeacon(
          `${process.env.VUE_APP_BASE_API}/api/data`,

          formData
        );

        localStorage.removeItem("time");
        localStorage.removeItem("tc");
      } else {
        console.log("刷新");

        _this.$store.commit("SET_REFREASH_PAGE", null);
      }
    };
    let time = localStorage.getItem("time")
      ? JSON.parse(localStorage.getItem("time"))
      : 0;
    if (!document.hidden) {
      this.residenceTimer = setInterval(() => {
        time++;
        localStorage.setItem("time", JSON.stringify(time));
      }, 1000);
    }
    window.onbeforeunload = function (e) {
      // // 取消默认事件
      // e.preventDefault();
      // // 设置消息提示文本
      // e.returnValue = "123";

      console.log(444, "onbeforeunload");
      beginTime = new Date().getTime();
    };
    document.addEventListener("visibilitychange", function () {
      if (!document.hidden) {
        _this.$store.commit("SET_REFRESH_PAGE", true);
        localStorage.removeItem("time");
        clearInterval(this.residenceTimer);
        this.residenceTimer = null;
        time = 0;
        this.residenceTimer = setInterval(() => {
          time++;
          localStorage.setItem("time", JSON.stringify(time));
        }, 1000);
      } else {
        clearInterval(this.residenceTimer);
        let t = JSON.parse(localStorage.getItem("time"));
        setTimeout(() => {
          if (_this.$store.state.refreshPage) {
            console.log("页面停留时间", t);
            clearInterval(this.residenceTimer);
            if (t >= 5) {
              trackClick({
                stay_secs: +t,
                behavior_tag: "Stay Page",
              });
            }
          } else {
            _this.$store.commit("SET_REFRESH_PAGE", true);
          }
        }, 100);
      }
    });
    // window.addEventListener("load", this.getTiming(), false);
  },
  methods: {
    isMobile() {
      const flag =
        navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        ) || document.body.clientWidth < 800;
      return flag;
    },
    isIos() {
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      return isiOS ? "ios" : "android";
    },
    judgeDeviceType() {
      if (this.isMobile()) {
        this.deviceType = this.isIos();
      } else {
        this.deviceType = "pc";
      }
      this.$store.commit("SET_DEVICE_TYPE", this.deviceType);
    },
    setLanguage() {
      const bLanguage = navigator.language;
      let lang = bLanguage.toLowerCase();
      if (lang.indexOf("ja") !== -1) {
        lang = "ja";
      } else if (lang.indexOf("ko") !== -1) {
        lang = "ko";
      } else if (lang.indexOf("tw") !== -1) {
        lang = "zh_TW";
      } else if (lang.indexOf("cn") !== -1) {
        lang = "zh_CN";
      } else if (lang.indexOf("ind") !== -1) {
        lang = "ind";
      } else if (lang.indexOf("ru") !== -1) {
        lang = "ru";
      } else if (lang.indexOf("es") !== -1) {
        lang = "es";
      } else if (lang.indexOf("fr") !== -1) {
        lang = "fr";
      } else if (lang.indexOf("de") !== -1) {
        lang = "gr";
      } else if (lang.indexOf("pt") !== -1) {
        lang = "pt";
      } else {
        lang = "en";
      }
      let l = localStorage.getItem("l");
      if (!l && window.atob(l) !== lang) {
        l = window.btoa(lang);
      }
      if (["en", "zh_CN", "zh_TW", "ru", "fr", "gr"].includes(window.atob(l))) {
        this.$store.dispatch("changeLanguages", window.atob(l));
      } else {
        this.$store.dispatch("changeLanguages", "en");
      }
    },
    uploadData(file) {
      reportData(
        { file, stream_prefix: process.env.VUE_APP_STREAM_PREFIX },
        true
      ).then((res) => {
        console.log("上报成功=>", res);
        localStorage.removeItem("tc");
      });
    },
    // 统计页面加载用时
    // getTiming() {
    //   setTimeout(() => {
    //     let t = window.performance.timing;
    //     let performanceInfo = [
    //       {
    //         key: "Redirect",
    //         desc: "网页重定向的耗时",
    //         "value(ms)": t.redirectEnd - t.redirectStart,
    //       },
    //       {
    //         key: "AppCache",
    //         desc: "检查本地缓存的耗时",
    //         "value(ms)": t.domainLookupStart - t.fetchStart,
    //       },
    //       {
    //         key: "DNS",
    //         desc: "DNS查询的耗时",
    //         "value(ms)": t.domainLookupEnd - t.domainLookupStart,
    //       },
    //       {
    //         key: "TCP",
    //         desc: "TCP链接的耗时",
    //         "value(ms)": t.connectEnd - t.connectStart,
    //       },
    //       {
    //         key: "Waiting(TTFB)",
    //         desc: "从客户端发起请求到接收响应的时间",
    //         "value(ms)": t.responseStart - t.requestStart,
    //       },
    //       {
    //         key: "Content Download",
    //         desc: "下载服务端返回数据的时间",
    //         "value(ms)": t.responseEnd - t.responseStart,
    //       },
    //       {
    //         key: "HTTP Total Time",
    //         desc: "http请求总耗时",
    //         "value(ms)": t.responseEnd - t.requestStart,
    //       },
    //       {
    //         key: "First Time",
    //         desc: "首包时间",
    //         "value(ms)": t.responseStart - t.domainLookupStart,
    //       },
    //       {
    //         key: "White screen time",
    //         desc: "白屏时间",
    //         "value(ms)": t.responseEnd - t.fetchStart,
    //       },
    //       {
    //         key: "Time to Interactive(TTI)",
    //         desc: "首次可交互时间",
    //         "value(ms)": t.domInteractive - t.fetchStart,
    //       },
    //       {
    //         key: "DOM Parsing",
    //         desc: "DOM 解析耗时",
    //         "value(ms)": t.domInteractive - t.responseEnd,
    //       },
    //       {
    //         key: "DOMContentLoaded",
    //         desc: "DOM 加载完成的时间",
    //         "value(ms)": t.domInteractive - t.navigationStart,
    //       },
    //       {
    //         key: "Loaded",
    //         desc: "页面load的总耗时",
    //         "value(ms)": t.loadEventEnd - t.navigationStart,
    //       },
    //     ];

    //     console.table(performanceInfo);
    //   }, 0);
    // },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss">
.cur_pointer {
  cursor: pointer;
}
div,
a,
img {
  -webkit-tap-highlight-color: transparent;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.nav-title {
  color: #739439;
  font-size: 2.1rem;
  display: block;
  flex-grow: 1;
  width: 100%;
  padding: 0.7rem 0.5rem;
}
.language_option {
  font-size: 1.4rem;
  color: rgb(120, 154, 57);
  text-align: left;
  padding: 2% 0 2% 9%;
  border-bottom: 0.18rem solid #789a39;
}
.language_option:nth-last-of-type(1) {
  border: none;
}
.cur_language {
  background-color: #739439;
  color: #fff;
}
.back_img_cls {
  background-image: url("https://static-sites.allstarunion.com/official_web_static/sprite.png");
}
.font-color {
  color: #789a39;
}
.font-color_v2 {
  color: #ffe775;
}
.position-f {
  position: relative;
}
.position-c {
  position: absolute;
}
</style>
