const en = {
  label: {
    language: "English",
    appoint_title: "Get a pre-order reward",
    email_tips: "Enter your e-mail address and receive an exclusive reward",
    ios_appoint: "Pre-order for iOS",
    Public_beta: "Open test for iOS starts in October, 2022!",
    submit: "Submit",
    Reached: "Achieved",
    cur_appoint: "XX people have already pre-ordered",
    appoint_reward: "Pre-order Rewards",
    page_title: '"Myths of Moonrise" iOS Pre-order Website',
    appoint_now: "Pre-order Now",
  },
  msg: {
    title: "Message",
    success: "Pre-order succeeded. Please stay tuned!",
    has_appoint: "You have already submitted the pre-order",
    know: "OK",
    submit_success: "Submitted",
    email_error: "Please enter the correct e-mail address",
    input_email: "E-mail address",
  },
  gift: {
    food: "5,000 Food",
    wood: "5,000 Wood",
    common_recruitment_currency: "Rare Recruitment Coin",
    diamond_supply_box: "Diamond Supply Chest",
    building_acceleration: "5-Min Building Speedup",
    accelerated_scientific_research: "5-Min Research Speedup",
    training_acceleration: "5-Min Training Speedup",
    manufacturing_acceleration: "5-Min Trap Producing Speedup",
    advanced_recruitment_currency: "Epic Recruitment Coin",
    fortifying_medicament: "1,000 Elixir",
    wizard_Jenny: "3★ Wizard - Jennie",
    witch_Matt: "4★ Wizard - Matt",
  },
  footer: {
    $1: "Privacy Policy",
    $2: "Terms of Service",
    $3: "Sichuan Public Network Security No. 51019002003535",
    $4: "Shu ICP No. 2020036928-1",
    $5: "Copyright @2019-2022 All Rights Reserved",
  },
};
export default en;
