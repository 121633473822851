var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"turntable"},[_c('div',{staticClass:"turntable-center",class:{ c_next: _vm.changeToNext, c_prev: _vm.changeToPrev }}),_c('div',{staticClass:"turntable-index",style:({
      'background-image':
        'url(' + _vm.navTitleList[_vm.activeNavIndex].index_bg + ')',
    })}),_c('div',{staticClass:"top-nav",class:{ t_next: _vm.changeToNext, t_prev: _vm.changeToPrev },style:({
      'background-image':
        'url(' + (_vm.navTitleList[_vm.activeNavIndex].topNav || '') + ')',
      width: _vm.navTitleList[_vm.activeNavIndex].top_width || '',
      left: _vm.navTitleList[_vm.activeNavIndex].top_left || '',
    }),on:{"click":function($event){return _vm.clickTurnTable('prev')}}}),_c('div',{staticClass:"center-nav",class:{ next: _vm.changeToNext, prev: _vm.changeToPrev },style:({
      'background-image':
        'url(' + _vm.navTitleList[_vm.activeNavIndex].centerNav + ')',
      width: _vm.navTitleList[_vm.activeNavIndex].center_width || '',
      left: _vm.navTitleList[_vm.activeNavIndex].center_left || '',
    })}),_c('div',{staticClass:"bottom-nav",class:{ b_next: _vm.changeToNext, b_prev: _vm.changeToPrev },style:({
      'background-image':
        'url(' + (_vm.navTitleList[_vm.activeNavIndex].bottomNav || '') + ')',
      width: _vm.navTitleList[_vm.activeNavIndex].bottom_width || '',
      left: _vm.navTitleList[_vm.activeNavIndex].bottom_left || '',
    }),on:{"click":function($event){return _vm.clickTurnTable('next')}}})])}
var staticRenderFns = []

export { render, staticRenderFns }