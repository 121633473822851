<template>
  <div class="home">
    <div
      class="content"
      :style="{
        'background-image': 'url(' + homeInfo.bg + ')',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': 'center center',
      }"
    >
      <div class="content-people">
        <div class="content-people-top">
          <span>{{ addCommas }}</span>
          <img src="@/assets/pc/gift/attach-top.png" alt="" />
        </div>
        <div class="content-people-bottom"></div>
      </div>
      <div class="content-top-bg"></div>
      <div class="content-center">
        <div
          class="content-center-item"
          v-for="(item, i) in giftList"
          :key="i"
          :style="{
            'background-image': 'url(' + item.bg + ')',
          }"
        ></div>
      </div>
      <div class="content-bottom-bg">
        <div class="line">
          <div
            v-for="(item, i) in 5"
            :key="i"
            :style="{
              left: countLeft(i),
            }"
            class="dot"
            :class="countReachNumDot"
          ></div>
          <div class="line-cover" :class="countReachNum"></div>
        </div>
      </div>

      <div class="content-introduce">
        <div
          class="content-introduce-item"
          v-for="(item, i) in giftList"
          :key="i"
          :style="{
            'background-image': 'url(' + item.introduce + ')',
          }"
        ></div>
      </div>
      <div class="content-reward_text">
        <p>
          特典を受け取るには、事前登録者数に応じた市役所レベルに到達している必要があります。それぞれ、5万人ではLv.2、10万人ではLv.3、20万人ではLv.4、35万人ではLv.5、50万人ではLv.7に到達するのが条件となります。事前登録者数報酬はゲーム内メールで配布されます。
        </p>

        <p>
          <span> ※このイベントは日本地域のユーザー限定です。 </span>
          <span> 　（特典受け取り期限：2024年1月5日まで） </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "@/tools/debounce.js";
import { trackClick } from "@/tools/trackClick";
import { convertUTCTimeToLocalTime } from "@/tools/convertUTCTimeToLocalTime";
export default {
  name: "component_name",
  data() {
    return {
      activePeopleNum: 0,
      peopleNumberObj: {
        0: { total: 89357, min: 4, max: 8 },
        1: { total: 170679, min: 3, max: 6 },
        2: { total: 241307, min: 3, max: 6 },
        3: { total: 280765, min: 1, max: 3 },
        4: { total: 321867, min: 1, max: 3 },
        5: { total: 369453, min: 1, max: 3 },
        6: { total: 405493, min: 1, max: 3 },
        7: { total: 450873, min: 1, max: 3 },
        8: { total: 514950, min: 1, max: 5 },
        9: { total: 584513, min: 3, max: 6 },
        10: { total: 633389, min: 1, max: 5 },
        11: { total: 699642, min: 1, max: 5 },
        12: { total: 765982, min: 1, max: 5 },
        13: { total: 804868, min: 1, max: 5 },
        14: { total: 886271, min: 3, max: 6 },
        15: { total: 936879, min: 3, max: 6 },
        16: { total: 1010067, min: 3, max: 6 },
        17: { total: 1030097, min: 3, max: 6 },
        18: { total: 1067854, min: 3, max: 6 },
        19: { total: 1098462, min: 3, max: 6 },
        20: { total: 1138726, min: 3, max: 6 },
        21: { total: 1179643, min: 3, max: 6 },
        22: { total: 1199873, min: 3, max: 6 },
        23: { total: 1227896, min: 3, max: 6 },
        24: { total: 1267843, min: 3, max: 6 },
        25: { total: 1293323, min: 3, max: 6 },
        26: { total: 1334211, min: 3, max: 6 },
        27: { total: 1376531, min: 3, max: 6 },
        28: { total: 1400356, min: 3, max: 6 },
        29: { total: 1453846, min: 3, max: 6 },
        30: { total: 1493847, min: 3, max: 6 },
      },
    };
  },
  watch: {
    activePeopleNum: {
      handler(val) {
        if (val) {
          console.log("当前预约人数=>", val);
        }
      },
    },
  },
  created() {},
  computed: {
    homeInfo() {
      return {
        bg: require("@/assets/pc/gift/bg.png"),
        name: "home",
      };
    },
    addCommas() {
      //把num转换为字符串
      var numStr = this.activePeopleNum.toString();
      //获取字符串长度
      var len = numStr.length;
      //计算需要加多少个逗号
      var commasCount = Math.floor((len - 1) / 3);
      //循环添加逗号
      for (var i = 0; i < commasCount; i++) {
        var index = len - (i + 1) * 3;
        numStr = numStr.substring(0, index) + ", " + numStr.substring(index);
      }
      return numStr;
    },
    giftList() {
      return [
        {
          bg: require("@/assets/pc/gift/award_5.png"),
          introduce: require("@/assets/pc/gift/introduce_5.png"),
        },
        {
          bg: require("@/assets/pc/gift/award_10.png"),
          introduce: require("@/assets/pc/gift/introduce_10.png"),
        },
        {
          bg: require("@/assets/pc/gift/award_20.png"),
          introduce: require("@/assets/pc/gift/introduce_20.png"),
        },
        {
          bg: require("@/assets/pc/gift/award_35.png"),
          introduce: require("@/assets/pc/gift/introduce_35.png"),
        },
        {
          bg: require("@/assets/pc/gift/award_50.png"),
          introduce: require("@/assets/pc/gift/introduce_50.png"),
        },
      ];
    },
    countReachNum() {
      if (this.isInRange(this.activePeopleNum, -1, 50000)) {
        return "line-null";
      } else if (this.isInRange(this.activePeopleNum, 50000, 100000)) {
        return "line-5";
      } else if (this.isInRange(this.activePeopleNum, 100000, 200000)) {
        return "line-10";
      } else if (this.isInRange(this.activePeopleNum, 200000, 350000)) {
        return "line-20";
      } else if (this.isInRange(this.activePeopleNum, 350000, 500000)) {
        return "line-35";
      } else {
        return "line-50";
      }
    },
    countReachNumDot() {
      if (this.isInRange(this.activePeopleNum, -1, 50000)) {
        return "dot-null";
      } else if (this.isInRange(this.activePeopleNum, 50000, 100000)) {
        return "dot-5";
      } else if (this.isInRange(this.activePeopleNum, 100000, 200000)) {
        return "dot-10";
      } else if (this.isInRange(this.activePeopleNum, 200000, 350000)) {
        return "dot-20";
      } else if (this.isInRange(this.activePeopleNum, 350000, 500000)) {
        return "dot-35";
      } else {
        return "dot-50";
      }
    },
    countLeft() {
      return (i) => {
        switch (i) {
          case 0:
            return "9%";
          case 1:
            return "29.1%";
          case 2:
            return "49.2%";
          case 3:
            return "69.4%";
          case 4:
            return "89.4%";

          default:
            break;
        }
      };
    },
  },
  mounted() {
    let today = new Date();
    // 对当前系统时间的偏移量
    let offset = today.getTimezoneOffset() * 60000;
    let utcTime = today.getTime() + offset + 9 * 60 * 60000; // 日区 = utc+9h
    let curTime = new Date(utcTime).getTime();
    // 结束时间的utc时间，转为本地时区时间，在计算对应utc时间戳
    let endLocalTime = convertUTCTimeToLocalTime("2024-01-04T15:00:00.000Z");
    let endUtcTime =
      new Date(endLocalTime).getTime() +
      new Date(endLocalTime).getTimezoneOffset() * 60000 +
      9 * 60 * 60000;
    let endTime = new Date(endUtcTime).getTime();
    console.log(444444, curTime, endTime);
    let curDay = 31 - Math.ceil((endTime - curTime) / (24 * 60 * 60000));
    console.log(123, curDay);
    let passTime =
      (new Date(utcTime).getHours() * 60 * 60 * 1000 +
        new Date(utcTime).getMinutes() * 60 * 1000 +
        new Date(utcTime).getSeconds() * 1000 +
        new Date(utcTime).getMilliseconds()) /
      10000;
    if (curDay >= 31) {
      this.timer = null;
      this.activePeopleNum = this.peopleNumberObj[30].total;
      return;
    } else if (curDay < 0) {
      this.timer = null;
      this.activePeopleNum = 0;
      return;
    }
    let { max, min } = this.peopleNumberObj[curDay];
    // 刷新，首次进入按照当天已过秒数根据增长曲线计算已有人数
    let hasGetTodayPeople = Math.round(passTime * (max + min));
    this.activePeopleNum =
      curDay == 0
        ? hasGetTodayPeople
        : this.peopleNumberObj[curDay - 1].total + hasGetTodayPeople;

    // 每日最后10s人数自动赋值当天目标最大值
    // 当天的23:59:50
    var todayEndFirstTime = new Date(
      new Date(new Date(utcTime).toLocaleDateString()).getTime() + 86390000
    ).getTime(); //毫秒
    // 第二天的00:00:00
    var todayEndLastTime = new Date(
      new Date(new Date(utcTime).toLocaleDateString()).getTime() + 86400000
    ).getTime(); //毫秒

    if (
      new Date(utcTime).getTime() >= todayEndFirstTime &&
      new Date(utcTime).getTime() <= todayEndLastTime
    ) {
      this.activePeopleNum = this.peopleNumberObj[curDay].total;
    }
  },

  methods: {
    isInRange(value, min, max) {
      return value >= min && value < max;
    },
  },

  destroyed() {},
};
</script>

<style lang="scss" scoped>
@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
    background-image: url("../../assets/pc/gift/active-point.png");
  }
}
@keyframes gradientAnimation_5 {
  0% {
    width: 0;
  }
  100% {
    width: calc(10% - 0.625rem);
  }
}
@keyframes gradientAnimation_10 {
  0% {
    width: 0;
  }
  50% {
    width: calc(10% - 0.625rem);
  }
  100% {
    width: calc(30% - 0.6rem);
  }
}

@keyframes gradientAnimation_20 {
  0% {
    width: 0;
  }
  25% {
    width: calc(10% - 0.625rem);
  }
  75% {
    width: calc(30% - 0.625rem);
  }
  100% {
    width: calc(50% - 0.5rem);
  }
}
@keyframes gradientAnimation_35 {
  0% {
    width: 0;
  }
  25% {
    width: calc(10% - 0.625rem);
  }
  50% {
    width: calc(30% - 0.625rem);
  }
  75% {
    width: calc(50% - 0.625rem);
  }
  100% {
    width: calc(70% - 0.3rem);
  }
}
@keyframes gradientAnimation_50 {
  0% {
    width: 0;
  }
  20% {
    width: calc(10% - 0.625rem);
  }
  40% {
    width: calc(30% - 0.625rem);
  }
  60% {
    width: calc(50% - 0.625rem);
  }
  80% {
    width: calc(70% - 0.625rem);
  }
  90% {
    width: calc(90% - 0.625rem);
  }
  100% {
    width: 100%;
  }
}
.home {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .content {
    width: 100%;
    height: 100%;
    position: relative;

    &-people {
      height: 5.65rem;
      width: 32.2rem;
      position: absolute;
      top: 1.5%;
      left: 50%;
      margin-left: -16.1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      &-top {
        width: 100%;
        height: 4.6rem;
        display: flex;
        justify-content: center;
        align-items: baseline;
        img {
          width: 4.45rem;
          height: 1.45rem;
        }
        span {
          font-size: 3.5rem;
          color: #bdae7d;
          margin-right: 0.5rem;
          background: linear-gradient(to bottom, #bdae7d, #d5c592);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 500;
          font-family: HYRuiYiSong;
        }
      }
      &-bottom {
        width: 100%;
        height: 1.5rem;
        background-image: url("../../assets/pc/gift/attach-bottom.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    &-top-bg {
      position: absolute;
      left: 19.95%;
      top: 18.15%;
      width: 59.9%;
      height: 6rem;
      background-image: url("../../assets/pc/gift/area-top.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
    }
    &-center {
      position: absolute;
      left: 18.75%;
      top: 24.15%;
      width: 61.9%;
      // height: 50.7%;
      height: 33rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-item {
        width: calc((100% - 10rem) / 5);
        height: 90%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    &-bottom-bg {
      position: absolute;
      left: 17.87%;
      top: 51.6%;
      width: 63.75%;
      height: 20rem;
      background-image: url("../../assets/pc/gift/area-bt.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
      .line {
        position: absolute;
        left: 0;
        bottom: -0rem;
        width: 100%;
        height: 1.5px;
        z-index: 99;
        background: linear-gradient(to right, #3e3c3e, #6a635d, #3e3c3e);
        .dot {
          width: 1.25rem;
          height: 1.25rem;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          position: absolute;
          top: 50%;
          margin-top: -0.625rem;
          z-index: 99999;
          background-image: url("../../assets/pc/gift/inactive-point.png");
        }

        .dot-5 {
          &:nth-child(-n + 1) {
            animation: scaleAnimation 0.5s ease-in-out 3s forwards;
            //   background-image: url("../../assets/mobile/home/active-point.png") !important;
          }
          &:not(:nth-child(-n + 1)) {
            background-image: url("../../assets/mobile/home/inactive-point.png") !important;
          }
        }
        .dot-10 {
          &:first-child {
            animation: scaleAnimation 0.5s ease-in-out 1.2s forwards !important;
          }
          &:nth-child(-n + 2) {
            animation: scaleAnimation 0.5s ease-in-out 3s forwards;
          }
          &:not(:nth-child(-n + 2)) {
            background-image: url("../../assets/mobile/home/inactive-point.png") !important;
          }
        }
        .dot-20 {
          &:first-child {
            animation: scaleAnimation 0.5s ease-in-out 0.8s forwards !important;
          }
          &:nth-child(2) {
            animation: scaleAnimation 0.5s ease-in-out 2s forwards !important;
          }
          &:nth-child(-n + 3) {
            animation: scaleAnimation 0.5s ease-in-out 3s forwards;
          }
          &:not(:nth-child(-n + 3)) {
            background-image: url("../../assets/mobile/home/inactive-point.png") !important;
          }
        }
        .dot-35 {
          &:first-child {
            animation: scaleAnimation 0.5s ease-in-out 0.8s forwards !important;
          }
          &:nth-child(2) {
            animation: scaleAnimation 0.5s ease-in-out 2s forwards !important;
          }
          &:nth-child(3) {
            animation: scaleAnimation 0.5s ease-in-out 3s forwards !important;
          }
          &:nth-child(-n + 4) {
            animation: scaleAnimation 0.5s ease-in-out 4s forwards;
          }
          &:not(:nth-child(-n + 4)) {
            background-image: url("../../assets/mobile/home/inactive-point.png") !important;
          }
        }
        .dot-50 {
          &:first-child {
            animation: scaleAnimation 0.5s ease-in-out 0.8s forwards !important;
          }
          &:nth-child(2) {
            animation: scaleAnimation 0.5s ease-in-out 2s forwards !important;
          }
          &:nth-child(3) {
            animation: scaleAnimation 0.5s ease-in-out 3s forwards !important;
          }
          &:nth-child(4) {
            animation: scaleAnimation 0.5s ease-in-out 4s forwards !important;
          }
          &:nth-child(5) {
            animation: scaleAnimation 0.5s ease-in-out 4.5s forwards !important;
          }
        }
        &-cover {
          // width: 100%;
          height: 2px;
          background: linear-gradient(to right, #3e3c3e, #e2cdb8, #898179);
          position: absolute;
          top: -0.05rem;
          left: 0rem;
          z-index: 999;
        }
      }

      .line-5 {
        animation: gradientAnimation_5 3s ease-in-out forwards;
      }
      .line-10 {
        animation: gradientAnimation_10 3s ease-in-out forwards;
      }
      .line-20 {
        animation: gradientAnimation_20 3s ease-in-out forwards;
      }
      .line-35 {
        animation: gradientAnimation_35 4s ease-in-out forwards;
      }
      .line-50 {
        animation: gradientAnimation_50 5s ease-in-out forwards;
      }
    }

    &-introduce {
      position: absolute;
      left: 21.925%;
      top: 87.02%;
      width: 56.15%;
      height: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-item {
        width: 6.85rem;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    &-reward_text {
      width: 70%;
      height: 7.5%;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -35%;
      text-align: left;
      p {
        color: #7e795a;
        margin: 0;
        font-family: HYRuiYiSong;

        &:last-child {
          text-align: right;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}
</style>
