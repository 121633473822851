var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"content",style:({
      'background-image': 'url(' + _vm.homeInfo.bg + ')',
      'background-size': 'cover',
      'background-repeat': 'no-repeat',
      'background-position': 'center center',
    })},[_c('div',{staticClass:"content-area"},[_c('div',{staticClass:"left-nav"},[_c('div',{staticClass:"left-nav-login",on:{"click":_vm.handelLogin}}),_c('div',{staticClass:"left-nav-box"},_vm._l((_vm.leftNavList),function(item,i){return _c('div',{key:i,staticClass:"channel"},[_c('div',{staticClass:"icon",style:({
                'background-image':
                  'url(' +
                  (_vm.activeNavList[i] ? item.active_bg : item.inactive_bg) +
                  ')',
              }),on:{"click":function($event){return _vm.jumpLink(item.name)},"mouseover":function($event){return _vm.handelTouchIcon('cover', i)},"mouseleave":function($event){return _vm.handelTouchIcon('leave', i)}}})])}),0)]),_c('div',{staticClass:"regis-area"},[_c('div',{staticClass:"center-icon"}),_c('div',{staticClass:"center-regis"},[_c('div',{staticClass:"input-box"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],ref:"emailInput",staticClass:"input",attrs:{"type":"text","placeholder":"E-mail"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}})]),_c('div',{staticClass:"description"}),_c('div',{staticClass:"protocol"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.choice),expression:"choice"}],staticClass:"checkbox",attrs:{"type":"checkbox","name":"fruits[]","checked":""},domProps:{"checked":Array.isArray(_vm.choice)?_vm._i(_vm.choice,null)>-1:(_vm.choice)},on:{"change":function($event){var $$a=_vm.choice,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.choice=$$a.concat([$$v]))}else{$$i>-1&&(_vm.choice=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.choice=$$c}}}}),_c('img',{attrs:{"src":require("@/assets/pc/home/regis/agree.png"),"alt":""}})])]),_c('div',{staticClass:"register-btn",on:{"click":_vm.handelRegister}}),_c('div',{staticClass:"download-btn"},[_c('div',{staticClass:"ios",on:{"click":function($event){return _vm.openLink('ios')}}}),_c('div',{staticClass:"android",on:{"click":function($event){return _vm.openLink('android')}}})])])])]),_c('div',{staticClass:"content-logo"})]),_c('Register',{ref:"register",on:{"changeRightNav":_vm.changeRightNav,"hiddenBottomDirIcon":_vm.closeDirection}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }