<template>
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide each swiper-no-swiping">
        <Home
          @changeRightNav="changeRightNav"
          @hiddenBottomDirIcon="hiddenBottomDirIcon"
        />
      </div>
      <div class="swiper-slide each swiper-no-swiping"><Gift /></div>
      <div class="swiper-slide each swiper-no-swiping">
        <Character @changeRightNav="changeRightNav" />
      </div>
      <div class="swiper-slide each swiper-no-swiping"><Movie /></div>
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination"></div>
    <BottomIndirection
      v-if="activeInfo.activeSlideIndex !== 3 && showBottomIndirection"
      bottomDistance="0.5rem"
      :showShortLine="false"
    />
    <Turntable
      ref="turntable"
      v-if="showTurnTable"
      @setCurrentCarousel="setSlideIndex"
    />
  </div>
</template>
<script>
import "swiper/swiper-bundle.css";
import Swiper from "swiper/bundle";
import Home from "./PC/Home.vue";
import Character from "./PC/Character.vue";
import Gift from "./PC/Gift.vue";
import Movie from "./PC/Movie.vue";
import Turntable from "@/components/Turntable";
// import { aesDecrypt } from "@/setting/aesUtil.js";
import BottomIndirection from "@/components/BottomIndirection";

export default {
  components: {
    Home,
    Gift,
    Character,
    Movie,
    Turntable,
    BottomIndirection,
  },

  provide() {
    return {
      activeSlideObj: this.activeInfo,
    };
  },
  data() {
    return {
      mySwiper: null,
      activeInfo: {
        activeSlideIndex: 0,
      },
      showTurnTable: true,
      isClickTurnTable: false,
      showBottomIndirection: true,
    };
  },

  mounted() {
    const _this = this;
    this.mySwiper = new Swiper(".swiper-container", {
      speed: 800, // 切换轮播速度
      mousewheel: true, // 支持鼠标滚轮滚动
      direction: "vertical",
      loop: false, // 循环模式选项
      noSwiping: true,
      preventInteractionOnTransition: true,
      longSwipes: true,
      longSwipesRatio: 0.6, // 调整阀值（0~1）
      on: {
        init: function () {
          //Swiper初始化了
          _this.activeInfo.activeSlideIndex = this.activeIndex;
        },
        slideChange: function () {
          _this.activeInfo.activeSlideIndex = this.activeIndex;
          if (_this.isClickTurnTable) {
            return;
          }
          _this.$refs.turntable.handelChangeTurnTable(this.activeIndex);
        },
      },
    });
  },
  methods: {
    setSlideIndex(i) {
      this.isClickTurnTable = true;
      this.mySwiper.slideTo(i, 1000, false);
      this.activeInfo.activeSlideIndex = i;
      setTimeout(() => {
        this.isClickTurnTable = false;
      }, 100);
    },
    jumpToHero(i) {
      this.mySwiper.slideTo(1, 1000, false);
      this.activeInfo.activeSlideIndex = 1;
    },
    changeRightNav(v) {
      console.log(123, v);
      if (!v) {
        this.mySwiper.disable();
      } else {
        this.mySwiper.enable();
      }
      this.showTurnTable = v;
    },
    hiddenBottomDirIcon(v) {
      this.showBottomIndirection = v;
    },
  },
  beforeDestroy() {
    this.mySwiper = null;
  },
  beforeRouteEnter(to, from, next) {
    const { userCode, app_id } = to.query;
    if (userCode) {
      // ios重定向跳转登录
      next((vm) => {
        vm.$parent.handelReqLogin(4, aesDecrypt(userCode));
      });
    }
    next();
  },
};
</script>
<style lang="scss" scope>
.swiper-container {
  width: 100%;
  height: 100vh !important;
}
.wiper-wrapper {
  width: 100%;
  height: 100vh !important;
}
.each {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:first-child {
    width: 100%;
    height: 100vh !important;
    // background-color: #fff;
  }
  &:nth-child(2) {
    width: 100%;
    height: 100vh;
    // background-color: #b50a0a;
  }
  &:nth-child(3) {
    width: 100%;
    height: 100vh;
    // background-color: #ae30a6;
  }
  &:nth-child(4) {
    width: 100%;
    height: 100vh;
    // background-color: #1c1c7e;
  }
}
</style>
