const TW = {
  label: {
    language: "繁体中文",
    appoint_title: "預約遊戲領取豪禮",
    email_tips: "輸入郵箱可獲得專屬獎勵",
    ios_appoint: "iOS用戶立即預約",
    Public_beta: "10月iOS全服公測",
    submit: "提交",
    Reached: "已達成",
    cur_appoint: "全球已有XX人預約",
    appoint_reward: "預約獎勵",
    page_title: "《暗影謎題》iOS預約站",
    appoint_now: "我要預約",
  },
  msg: {
    title: "提示",
    success: "預約成功，請等待開服通知",
    has_appoint: "已預約，請勿重複提交",
    know: "知道了",
    submit_success: "提交成功",
    email_error: "郵箱格式錯誤，請重新填寫",
    input_email: "請輸入郵箱",
  },
  gift: {
    food: "5000食物",
    wood: "5000木材",
    common_recruitment_currency: "普通招募幣",
    diamond_supply_box: "鑽石補給箱",
    building_acceleration: "5分鐘建築加速",
    accelerated_scientific_research: "5分鐘科研加速",
    training_acceleration: "5分鐘部隊訓練加速",
    manufacturing_acceleration: "5分鐘陷阱製造加速",
    advanced_recruitment_currency: "高級招募幣",
    fortifying_medicament: "強化藥劑1000",
    wizard_Jenny: "3星巫師 珍妮",
    witch_Matt: "4星巫師 馬特",
  },
  footer: {
    $1: "隱私協議",
    $2: "用戶協議",
    $3: "川公网安备 51019002003535号",
    $4: "蜀ICP备2020036928号-1",
    $5: "Copyright @2019-2022 版权所有",
  },
};
export default TW;
