<template>
  <div class="footer">
    <div class="footer-content">
      <img src="../assets/icon_logo_02.png" :class="countImgSize" alt="LOGO" />
      <div
        class="footer-tips"
        :style="{
          fontSize: '10px',
        }"
      >
        <div class="footer-tips-owner">
          ©️2023 STAR UNION GAME - ALL RIGHTS RESERVED
        </div>

        <div>
          <div :style="{ margin: '0 0 0px' }">
            <a
              href="https://static-sites.allstarunion.com/service/general_privacy_ja.html"
              class="link"
              target="_blank"
              >プライバシーポリシー</a
            >
            <span :style="{ margin: '0px 2px' }"> | </span>
            <a
              href="https://static-sites.allstarunion.com/service/general_protocol_ja.html"
              class="link"
              target="_blank"
              >利用規約</a
            >
          </div>
          <div>
            <a
              href="https://static-sites.allstarunion.com/service/general_instructions_ja.html"
              class="link"
              target="_blank"
              >資金決済法に基づく表示</a
            >
            <span :style="{ margin: '0px 2px' }"> | </span>
            <a
              href="https://static-sites.allstarunion.com/service/general_commodity_ja.html"
              class="link"
              target="_blank"
              >特定商取引法に基づく表示</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      protocolAddress: {
        //隐私协议
        privacy: {
          ZH: "https://static-sites.allstarunion.com/service/general_privacy_cn.html",
          TW: "https://static-sites.allstarunion.com/service/general_privacy_tw.html",
          EN: "https://static-sites.allstarunion.com/service/general_privacy_en.html",
          KO: "https://static-sites.allstarunion.com/service/general_privacy_ko.html",
        },
        // 服务条款
        service: {
          ZH: "https://static-sites.allstarunion.com/service/general_protocol_cn.html",
          TW: "https://static-sites.allstarunion.com/service/general_protocol_tw.html",
          EN: "https://static-sites.allstarunion.com/service/general_protocol_en.html",
          KO: "https://static-sites.allstarunion.com/service/general_protocol_ko.html",
        },
      },
    };
  },
  computed: {
    ...mapState(["mobile", "language"]),
    /**
     * @description: 移动端对log图片缩小处理
     * @param {}
     * @return 返回类名
     */
    countImgSize() {
      return "mobile-img";
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 12.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #121212;

  &-content {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mobile-img {
    width: 90px;
    height: 38px;
  }
  &-tips {
    // width: 136px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    color: #fff;
    margin-left: 10px;
    white-space: nowrap;
    .link {
      font-size: 11px;
      color: #fff;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &-owner {
      font-size: 11px;
      // color: #fff;
      // margin-bottom: 5px;
    }
  }
}
</style>
