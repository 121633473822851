<template>
  <div class="home">
    <div
      class="content"
      :style="{
        'background-image': 'url(' + homeInfo.bg + ')',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': 'center center',
      }"
    >
      <div class="video-container">
        <div class="play-bg">
          <div class="swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide each-video">
                <img
                  src="@/assets/carousel_img1.png"
                  alt=""
                  class="carousel-img"
                />
              </div>
              <!-- <div class="swiper-slide each-video">
                <img
                  src="@/assets/carousel_img2.png"
                  alt=""
                  class="carousel-img"
                />
              </div> -->
              <div class="swiper-slide each-video">
                <video
                  id="video"
                  src="@/assets/video.mp4"
                  width="100%"
                  ref="first_video"
                  @pause="pauseVideo('first_video')"
                  poster="@/assets/video_poster.png"
                ></video>
                <img
                  src="@/assets/pc/video/play.png"
                  alt=""
                  @click="playVideo('first_video')"
                  class="play-bt"
                  v-if="!isPlayVideo"
                />
              </div>
              <div class="swiper-slide each-video">
                <img
                  src="@/assets/carousel_img3.png"
                  alt=""
                  class="carousel-img"
                />
              </div>
              <div class="swiper-slide each-video">
                <img
                  src="@/assets/carousel_img4.png"
                  alt=""
                  class="carousel-img"
                />
              </div>
              <div class="swiper-slide each-video">
                <img
                  src="@/assets/carousel_img5.png"
                  alt=""
                  class="carousel-img"
                />
              </div>
            </div>
          </div>
          <div class="button-prev"></div>
          <div class="button-next"></div>
          <div class="pagination">
            <span
              :class="
                i === activeSlideIndex ? 'active-slide' : 'inactive-slide'
              "
              v-for="(item, i) in 5"
              :key="i"
              @click="setCurrentSlide(i)"
            ></span>
          </div>
          <!-- <video
            src="@/assets/pc/video/video.mp4"
            width="100%"
            controls
          ></video> -->
        </div>
      </div>
    </div>
    <TheFooter />
  </div>
</template>

<script>
import "swiper/swiper-bundle.css";
import Swiper from "swiper/bundle";
import TheFooter from "@/components/TheFooter";
import { trackClick } from "@/tools/trackClick.js";

export default {
  name: "component_name",
  components: {
    TheFooter,
  },
  data() {
    return {
      mySwiper: null,
      activeSlideIndex: 1,
      isPlayVideo: false,
    };
  },
  created() {},
  computed: {
    homeInfo() {
      return {
        bg: require("../../assets/pc/video/video_bg.png"),
        name: "home",
      };
    },
  },
  mounted() {
    const _this = this;

    this.mySwiper = new Swiper(".swiper", {
      //导航按钮
      navigation: {
        nextEl: ".button-next",
        prevEl: ".button-prev",
      },
      initialSlide: 2,
      effect: "slide", //淡入淡出效果
      fadeEffect: {
        crossFade: true,
      },
      loop: true, //无限循环
      speed: 1000, //切换速度

      // autoplay: {
      //   delay: 3000, //自动播放间隔时间
      //   disableOnInteraction: false, //用户交互后是否停止播放
      // },
      on: {
        init: function () {
          //Swiper初始化了
          this.activeIndex = 2;
          _this.activeSlideIndex = this.activeIndex;
        },
        slideChange: function () {
          // _this.$refs.first_video.pause();
          if (this.activeIndex === 5) {
            _this.activeSlideIndex = 0;
          } else if (this.activeIndex === 6) {
            _this.activeSlideIndex = 1;
          } else {
            _this.activeSlideIndex = this.activeIndex;
          }
        },
      },
    });

    //如果你初始化时没有定义Swiper实例，后面也可以通过Swiper的HTML元素来获取该实例
    // new Swiper(".swiper");
    // var mySwiper = document.querySelector(".swiper").swiper;
    // mySwiper.slideNext();
    this.$nextTick(() => {
      let my_video = this.$refs.first_video;
      my_video.addEventListener("pause", function (e) {
        // 检查是否由用户交互引起的暂停（例如，点击进度条）
        if (my_video.seeking) {
          // 如果当前正在搜索/拖动进度，继续播放
          _this.isPlayVideo = true;
          my_video.play();
        } else {
          _this.isPlayVideo = false;
          my_video.pause();
          my_video.controls = false;
        }
      });
    });
  },

  methods: {
    pauseVideo(name) {},
    playVideo(name) {
      this.isPlayVideo = true;
      this.$refs[name].play();
      this.$refs[name].controls = true;
      trackClick({
        behavior_tag: "Play Video",
      });
    },
    setCurrentSlide(i) {
      this.mySwiper.slideTo(i, 1000, false);
      this.activeSlideIndex = i;
    },
  },

  destroyed() {},
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .content {
    width: 100%;
    height: 100vh;
    position: relative;
  }
}
.video-container {
  width: 100%;
  height: 32rem;
  background-image: url("../../assets/pc/video/content-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  margin-top: -16rem;
  left: 0;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  .play-bg {
    width: 73rem;
    height: 41rem;
    position: absolute;
    left: 50%;
    margin-left: -36.5rem;
    top: -4.44rem;
    background-image: url("../../assets/pc/video/play_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 99;
    .swiper {
      width: 98%;
      height: 97%;

      //   object-fit: contain; /* 自适应宽度或高度 */
      position: absolute;
      left: 50%;
      top: 1.5%;
      margin-left: -49%;
      //   margin-top: -25%;
      z-index: 999;
      overflow: hidden;
    }

    .swiper-slide {
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 18px;
      // background: #fff;
      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .play-bt {
        width: 8.5rem;
        height: 8.5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -4.25rem;
        margin-top: -4.25rem;
        z-index: 9999;
        cursor: pointer;
      }
      .carousel-img {
        width: 100%;
        height: 100%;
      }
    }
    .button {
      &-prev {
        width: 5rem;
        height: 7.88rem;
        background-image: url("../../assets/pc/video/prev-icon.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: absolute;
        top: 50%;
        margin-top: -3.94rem;
        left: -5rem;
        cursor: pointer;
      }
      &-next {
        width: 5rem;
        height: 7.88rem;
        background-image: url("../../assets/pc/video/prev-icon.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: absolute;
        right: -5rem;
        top: 50%;
        margin-top: -3.94rem;
        transform: rotate(180deg);
        cursor: pointer;
      }
    }
    .pagination {
      width: 14.25rem;
      height: 1.25rem;
      position: absolute;
      bottom: -2.5rem;
      left: 50%;
      margin-left: -7.125rem;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      span {
        width: 1.2rem;
        height: 100%;
        display: inline-block;
        background-image: url("../../assets/pc/video/inactive-point.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .active-slide {
        background-image: url("../../assets/pc/video/active-point.png");
      }
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover; /* 自适应宽度或高度 */
      //   margin-top: -25%;
    }
  }
}

.video-container video {
}
</style>
