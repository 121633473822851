const ko = {
  label: {
    language: "한국어",
    appoint_title: "사전 등록하여 풍성한 보상 받기!",
    email_tips: "메일을 입력하여 전속 보상 받기",
    ios_appoint: "iOS 버전 즉시 등록",
    Public_beta: "iOS 버전 10월 그랜드 오픈!",
    submit: "제출",
    Reached: "달성 완료",
    cur_appoint: "사전 등록 인원수: XX",
    appoint_reward: "사전 등록 보상",
    page_title: "「미스 오브 문라이즈」iOS 사전 등록 ",
    appoint_now: "록하러 가기",
  },
  msg: {
    title: "알림",
    success: "사전 등록 완료! 기대하세요!",
    has_appoint: "사전 등록 완료! 중복 제출 불가!",
    know: "예",
    submit_success: "제출 완료",
    email_error: "잘못된 메일 주소입니다. 다시 입력하세요.",
    input_email: "请输入邮箱",
  },
  gift: {
    food: "5000식량",
    wood: "5000목재",
    common_recruitment_currency: "일반 모집 코인",
    diamond_supply_box: "다이아 보급 상자",
    building_acceleration: "5분 건설 속도 가속",
    accelerated_scientific_research: "5분 개발 속도 가속",
    training_acceleration: "5분 훈련 속도 가속",
    manufacturing_acceleration: "5분 함정 제작 속도 가속",
    advanced_recruitment_currency: "전설 모집 코인",
    fortifying_medicament: "강화 포션",
    wizard_Jenny: "3성 주술사 제니x1",
    witch_Matt: "4성 주술사 마트x1",
  },
  footer: {
    $1: "개인 정보 보호 정책",
    $2: "이용자 약관",
    $3: "Sichuan Public Network Security No. 51019002003535",
    $4: "Shu ICP No. 2020036928-1",
    $5: "Copyright @2019-2022 All Rights Reserved.",
  },
};
export default ko;
