<template>
  <div class="video">
    <div
      class="content"
      :style="{
        'background-image': 'url(' + homeInfo.bg + ')',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': 'center center',
      }"
    >
      <div class="content-title"></div>
      <div class="content-video">
        <div class="content-video-box">
          <div class="left-bg"></div>
          <div class="center">
            <div class="swiper-m">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <img src="@/assets/carousel_img1.jpg" class="carousel-img" />
                </div>
                <div class="swiper-slide">
                  <img src="@/assets/carousel_img2.jpg" class="carousel-img" />
                </div>
                <div class="swiper-slide first-slide">
                  <div class="default-video">
                    <img
                      src="@/assets/mobile/video/play.png"
                      alt=""
                      @click="playVideo"
                      class="play-btn"
                      v-if="!isPlaying"
                    />
                    <video
                      src="@/assets/video.mp4"
                      poster="../../assets/mobile/video/default-video-bg.png"
                      ref="video"
                      @pause="pauseVideo"
                    ></video>
                  </div>
                </div>
                <div class="swiper-slide">
                  <img src="@/assets/carousel_img3.jpg" class="carousel-img" />
                </div>
                <div class="swiper-slide">
                  <img src="@/assets/carousel_img4.jpg" class="carousel-img" />
                </div>
              </div>
            </div>
          </div>
          <div class="right-bg"></div>
        </div>
        <div class="content-video-switchover">
          <img
            src="@/assets/mobile/video/prev-icon.png"
            class="button-prev"
            alt=""
          />
          <div class="indicator">
            <div
              class="indicator-item"
              v-for="(item, index) in 5"
              :key="index"
              :style="{
                'background-image':
                  'url(' +
                  (index == activeSlideIndex
                    ? indicatorBg.active_bg
                    : indicatorBg.inactive_bg) +
                  ')',
              }"
              @click="setCurrentSlide(index)"
            ></div>
          </div>
          <img
            src="@/assets/mobile/video/next-icon.png"
            class="button-next"
            alt=""
          />
        </div>
      </div>
    </div>
    <FooterMobile />
  </div>
</template>

<script>
import "swiper/swiper-bundle.css";
import Swiper from "swiper/bundle";
import { trackClick } from "@/tools/trackClick.js";
import FooterMobile from "@/components/FooterMobile";
export default {
  name: "component_name",
  components: {
    FooterMobile,
  },
  data() {
    return {
      mySwiper: null,
      activeSlideIndex: 0,
      isPlaying: false,
    };
  },
  created() {},

  mounted() {
    const _this = this;

    this.mySwiper = new Swiper(".swiper-m", {
      //导航按钮
      navigation: {
        nextEl: ".button-next",
        prevEl: ".button-prev",
      },
      effect: "slide", //淡入淡出效果
      fadeEffect: {
        crossFade: true,
      },
      initialSlide: 2,
      speed: 1000, //切换速度
      spaceBetween: 10,
      // autoplay: {
      //   delay: 3000, //自动播放间隔时间
      //   disableOnInteraction: false, //用户交互后是否停止播放
      // },
      on: {
        init: function () {
          //Swiper初始化了
          _this.activeSlideIndex = this.activeIndex;
        },
        slideChange: function () {
          _this.activeSlideIndex = this.activeIndex;
        },
      },
    });
  },
  computed: {
    homeInfo() {
      return {
        bg: require("@/assets/mobile/video/video-bg.jpg"),
        name: "home",
      };
    },
    indicatorBg() {
      return {
        active_bg: require("@/assets/mobile/video/active-point.png"),
        inactive_bg: require("@/assets/mobile/video/inactive-point.png"),
      };
    },
  },
  methods: {
    playVideo() {
      this.isPlaying = true;
      this.$refs.video.play();
      this.$refs.video.controls = true;
      trackClick({
        behavior_tag: "Play Video",
      });
    },
    pauseVideo() {
      this.isPlaying = false;
      this.$refs.video.pause();
      this.$refs.video.controls = false;
    },
    setCurrentSlide(i) {
      this.mySwiper.slideTo(i, 1000, false);
      this.activeSlideIndex = i;
    },
  },

  destroyed() {},
};
</script>

<style lang="scss" scoped>
.video {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .content {
    width: 100%;
    height: 100%;
    position: relative;
    &-title {
      width: 20.7rem;
      height: 4.5rem;
      position: absolute;
      top: 25.5%;
      left: 50%;
      margin-left: -10rem;
      background-image: url("../../assets/mobile/video/title.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
    }
    &-video {
      width: 100%;
      height: 46.35rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      position: absolute;
      top: 34.3%;
      left: 0;
      &-box {
        width: 100%;
        height: 32.17rem;
        position: absolute;
        top: 0;
        left: 0;
        .left-bg {
          width: 20.2rem;
          height: 25.25rem;
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -12.625rem;
          background-image: url("../../assets/mobile/video/video-left-bg.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center center;
        }
        .center {
          width: 57.42rem;
          height: 32.17rem;
          position: absolute;
          left: 50%;
          top: 0%;
          margin-left: -28.71rem;

          z-index: 99;
          .swiper-m {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            overflow: hidden;
            z-index: 999;
          }
          .swiper-slide {
            width: 100%;
            height: 100%;
            text-align: center;
            font-size: 18px;
            /* Center slide text vertically */
            background-image: url("../../assets/pc/video/play_bg.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            .carousel-img {
              width: 98%;
              height: 97%;
            }
          }
          .first-slide {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            .default-video {
              width: 98%;
              height: 98%;
              //   background-image: url("../../assets/mobile/video/default-video-bg.png");
              //   background-size: 100% 100%;
              //   background-repeat: no-repeat;
              //   background-position: center center;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              video {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .play-btn {
                width: 11.33rem;
                height: 11.33rem;
                position: absolute;
                left: 50%;
                top: 50%;
                margin-top: -5.665rem;
                margin-left: -5.665rem;
                z-index: 99;
              }
            }
          }
        }
        .right-bg {
          width: 20.2rem;
          height: 25.25rem;
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -12.625rem;
          background-image: url("../../assets/mobile/video/video-left-bg.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
      &-switchover {
        width: calc(100% - 10rem);
        height: 8.67rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 5rem;
        img {
          width: 5.2rem;
          height: 100%;
        }
        .indicator {
          width: 28.75rem;
          height: 2.7rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          &-item {
            width: 2.5rem;
            height: 2.5rem;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
          }
        }
      }
    }
  }
}
</style>
