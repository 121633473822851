var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"video"},[_c('div',{staticClass:"content",style:({
      'background-image': 'url(' + _vm.homeInfo.bg + ')',
      'background-size': 'cover',
      'background-repeat': 'no-repeat',
      'background-position': 'center center',
    })},[_c('div',{staticClass:"content-title"}),_c('div',{staticClass:"content-video"},[_c('div',{staticClass:"content-video-box"},[_c('div',{staticClass:"left-bg"}),_c('div',{staticClass:"center"},[_c('div',{staticClass:"swiper-m"},[_c('div',{staticClass:"swiper-wrapper"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"swiper-slide first-slide"},[_c('div',{staticClass:"default-video"},[(!_vm.isPlaying)?_c('img',{staticClass:"play-btn",attrs:{"src":require("@/assets/mobile/video/play.png"),"alt":""},on:{"click":_vm.playVideo}}):_vm._e(),_c('video',{ref:"video",attrs:{"src":require("@/assets/video.mp4"),"poster":require("../../assets/mobile/video/default-video-bg.png")},on:{"pause":_vm.pauseVideo}})])]),_vm._m(2),_vm._m(3)])])]),_c('div',{staticClass:"right-bg"})]),_c('div',{staticClass:"content-video-switchover"},[_c('img',{staticClass:"button-prev",attrs:{"src":require("@/assets/mobile/video/prev-icon.png"),"alt":""}}),_c('div',{staticClass:"indicator"},_vm._l((5),function(item,index){return _c('div',{key:index,staticClass:"indicator-item",style:({
              'background-image':
                'url(' +
                (index == _vm.activeSlideIndex
                  ? _vm.indicatorBg.active_bg
                  : _vm.indicatorBg.inactive_bg) +
                ')',
            }),on:{"click":function($event){return _vm.setCurrentSlide(index)}}})}),0),_c('img',{staticClass:"button-next",attrs:{"src":require("@/assets/mobile/video/next-icon.png"),"alt":""}})])])]),_c('FooterMobile')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"swiper-slide"},[_c('img',{staticClass:"carousel-img",attrs:{"src":require("@/assets/carousel_img1.jpg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"swiper-slide"},[_c('img',{staticClass:"carousel-img",attrs:{"src":require("@/assets/carousel_img2.jpg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"swiper-slide"},[_c('img',{staticClass:"carousel-img",attrs:{"src":require("@/assets/carousel_img3.jpg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"swiper-slide"},[_c('img',{staticClass:"carousel-img",attrs:{"src":require("@/assets/carousel_img4.jpg")}})])}]

export { render, staticRenderFns }