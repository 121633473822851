<template>
  <div class="header">
    <div class="header-left"></div>
    <div class="header-right" @click="showMenu = true"></div>
    <div class="menu" :class="showMenu ? 'active-menu' : 'inactive-menu'">
      <div class="menu-header">
        <div class="menu-header-left"></div>
        <div class="menu-header-right" @click="showMenu = false"></div>
      </div>
      <div class="menu-nav">
        <div
          v-for="(item, i) in navList"
          :key="i"
          :class="item.name === activeNav ? 'active-nav each-nav' : 'each-nav'"
          @click="jumpToNav(i)"
        >
          <div
            :style="{
              'background-image': 'url(' + item.bg + ')',
              'background-size': 'cover',
              'background-repeat': 'no-repeat',
              'background-position': 'center center',
              width: item.width,
              height: '4rem',
            }"
          ></div>
        </div>
      </div>
      <div class="menu-download">
        <div class="menu-download-apple" @click="jumpToStore('ios')"></div>
        <div class="menu-download-google" @click="jumpToStore('google')"></div>
        <div class="menu-tripartite">
          <div
            class="menu-tripartite-twitter"
            @click="jumpToStore('twitter')"
          ></div>
          <div class="menu-tripartite-youtube" @click="jumpToStore('youtube')">
            <div class="border-line"></div>
          </div>
          <div class="menu-tripartite-line" @click="jumpToStore('line')"></div>
        </div>
      </div>
      <div class="menu-newline"></div>
    </div>
  </div>
</template>

<script>
import { trackClick } from "@/tools/trackClick.js";
export default {
  data() {
    return {
      activeNav: "character",
      showMenu: false,
    };
  },
  inject: ["deviceType", "activeSlideObj"],
  watch: {
    activeSlideObj: {
      handler(val) {
        let { activeSlideIndex } = val;
        console.log("当前激活索引", activeSlideIndex);
        let obj = {
          0: "home",
          1: "character",
          2: "video",
        };
        this.activeNav = obj[activeSlideIndex];
      },
      immediate: true,
      deep: true,
    },
  },

  computed: {
    language() {
      return this.$store.state.language;
    },
    languages() {
      const { languages, language } = this.$store.state;
      return languages[language];
    },
    navList() {
      return [
        {
          bg: require("@/assets/mobile/home/home.png"),
          width: "15.6rem",
          name: "home",
        },
        {
          bg: require("@/assets/mobile/home/character.png"),
          width: "31.5rem",
          name: "character",
        },
        // {
        //   bg: require("@/assets/mobile/home/video.png"),
        //   width: "16.7rem",
        //   name: "video",
        // },
      ];
    },
  },
  methods: {
    /*语言切换 */
    jumpToNav(index) {
      this.showMenu = false;
      this.$emit("setCurrentIndex", index);
    },
    jumpToStore(way) {
      let urlObj = {
        ios: {
          link: "https://apps.apple.com/jp/app/nations-of-darkness/id6469768494",
          track_name: "Click Ios store",
        },
        google: {
          link: "https://play.google.com/store/apps/details?id=com.allstarunion.nod.jp",
          track_name: "Click Google store",
        },
        twitter: {
          link: "https://twitter.com/NoDgameJP",
          track_name: "Click Twitter",
        },
        // wait
        youtube: {
          link: "https://www.youtube.com/@NoDgames",
          track_name: "Click Youtube",
        },
        // wait
        line: {
          link: "https://lin.ee/J9fiO9G",
          track_name: "Click Line",
        },
      };
      let url = urlObj[way].link;
      window.open(url, "_blank");
      trackClick({
        behavior_tag: urlObj[way].track_name,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/* 定义动画细节 */
@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.header {
  width: 100vw;
  height: 10rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 99;
  padding: 1.5rem 1.5rem;
  background-image: url("../assets/mobile/home/header_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  &-left {
    width: 21.25rem;
    height: 8.17rem;
    background-image: url("../assets/mobile/logo.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  &-right {
    width: 5.48rem;
    height: 4.08rem;
    background-image: url("../assets/mobile/home/menu_icon.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .menu {
    width: 100%;
    height: 100vh;
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    &-header {
      width: 100%;
      height: 10rem;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 0;
      left: 0;
      padding: 1.5rem 1.5rem;
      box-sizing: border-box;
      &-left {
        width: 21.25rem;
        height: 8.17rem;
        background-image: url("../assets/mobile/logo.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &-right {
        width: 5.83rem;
        height: 5.75rem;
        background-image: url("../assets/mobile/home/close.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
      }
    }
    &-nav {
      width: 100%;
      height: 15rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      flex-direction: column;
      top: 22%;
      left: 0;
      .each-nav {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 4rem;
        cursor: pointer;
      }
      .active-nav {
        position: relative !important;
        &::after {
          width: 100%;
          height: 0.7rem;
          content: "";
          position: absolute;
          top: 1.65rem;
          left: 0;
          background-image: url("../assets/mobile/home/active-nav-bg.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          z-index: 999;
        }
      }
    }
    &-download {
      width: 21.67rem;
      height: 22.56rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      position: absolute;
      bottom: 26.7%;
      left: 50%;
      margin-left: -10.835rem;

      &-apple {
        background-image: url("../assets/mobile/home/App_Store.png");
        width: 100%;
        height: 7.92rem;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &-google {
        width: 100%;
        height: 7.92rem;
        background-image: url("../assets/mobile/home/google-play.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        margin-top: 1rem;
      }
    }
    &-tripartite {
      width: 100%;
      height: 6.25rem;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 1.75rem;
      &-twitter {
        width: 4rem;
        height: 3.45rem;
        background-image: url("../assets/mobile/tt.png");
        // background-image: url("../assets/mobile/home/twitter.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &-youtube {
        width: 7.67rem;
        height: 6.25rem;
        background-image: url("../assets/mobile/home/youtube-bd.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        display: flex;
        justify-content: center;
        align-items: center;
        .border-line {
          width: 4rem;
          height: 3rem;
          background-image: url("../assets/mobile/home/youtube.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
      &-line {
        width: 4rem;
        height: 4rem;
        background-image: url("../assets/mobile/home/line.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    &-newline {
      width: 49rem;
      height: 9rem;
      background-image: url("../assets/mobile/home/NewLineFilms.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      bottom: 2.1%;
      left: 50%;
      margin-left: -24.5rem;
    }
  }
  .active-menu {
    animation-name: slideInFromTop;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
  }
  .inactive-menu {
    transform: translateY(-100%);
  }
}
</style>
