<template>
  <div class="turntable">
    <div
      class="turntable-center"
      :class="{ c_next: changeToNext, c_prev: changeToPrev }"
    ></div>
    <div
      class="turntable-index"
      :style="{
        'background-image':
          'url(' + navTitleList[activeNavIndex].index_bg + ')',
      }"
    ></div>
    <div
      class="top-nav"
      :style="{
        'background-image':
          'url(' + (navTitleList[activeNavIndex].topNav || '') + ')',
        width: navTitleList[activeNavIndex].top_width || '',
        left: navTitleList[activeNavIndex].top_left || '',
      }"
      @click="clickTurnTable('prev')"
      :class="{ t_next: changeToNext, t_prev: changeToPrev }"
    ></div>
    <div
      class="center-nav"
      :style="{
        'background-image':
          'url(' + navTitleList[activeNavIndex].centerNav + ')',
        width: navTitleList[activeNavIndex].center_width || '',
        left: navTitleList[activeNavIndex].center_left || '',
      }"
      :class="{ next: changeToNext, prev: changeToPrev }"
    ></div>
    <div
      class="bottom-nav"
      :style="{
        'background-image':
          'url(' + (navTitleList[activeNavIndex].bottomNav || '') + ')',
        width: navTitleList[activeNavIndex].bottom_width || '',
        left: navTitleList[activeNavIndex].bottom_left || '',
      }"
      @click="clickTurnTable('next')"
      :class="{ b_next: changeToNext, b_prev: changeToPrev }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "component_name",
  data() {
    return {
      activeNavIndex: 0,
      changeToPrev: false,
      changeToNext: false,
      isClickTurnTable: false,
    };
  },
  inject: ["activeSlideObj"],
  watch: {
    activeNavIndex: {
      handler(val) {
        this.$emit("setCurrentCarousel", val);
      },
    },
  },
  created() {},
  computed: {
    navTitleList() {
      return [
        {
          //   topNav: require("@/assets/pc/home/movie.png"),
          centerNav: require("@/assets/pc/home/active-home.png"),
          bottomNav: require("@/assets/pc/home/gift.png"),
          index_bg: require("@/assets/pc/home/first-index.png"),
          bottom_width: "2.75rem",
          bottom_left: "-1.64rem",
        },
        {
          topNav: require("@/assets/pc/home/home.png"),
          centerNav: require("@/assets/pc/home/active-gift.png"),
          bottomNav: require("@/assets/pc/home/character.png"),
          index_bg: require("@/assets/pc/home/second-index.png"),
          center_width: "6.85rem",
          center_left: "-6.85rem",
          bottom_width: "6.75rem",
          bottom_left: "-5.64rem",
        },
        {
          topNav: require("@/assets/pc/home/gift.png"),
          centerNav: require("@/assets/pc/home/active-character.png"),
          bottomNav: require("@/assets/pc/home/movie.png"),
          index_bg: require("@/assets/pc/home/three-indx.png"),
          center_width: "14.8rem",
          center_left: "-14.8rem",
          top_width: "2.75rem",
          top_left: "-1.64rem",
        },
        {
          topNav: require("@/assets/pc/home/character.png"),
          centerNav: require("@/assets/pc/home/active-movie.png"),
          //   bottomNav: require("@/assets/pc/home/home.png"),
          index_bg: require("@/assets/pc/home/four-index.png"),
          top_width: "5.75rem",
          top_left: "-4.64rem",
        },
      ];
    },
  },
  mounted() {},

  methods: {
    handelChangeTurnTable(value) {
      if (value < this.activeNavIndex) {
        this.changeNav("prev");
      } else {
        this.changeNav("next");
      }
    },
    clickTurnTable(w) {
      this.changeNav(w);
    },
    changeNav(direction) {
      if (direction === "next") {
        this.changeToNext = true;
        setTimeout(() => {
          if (this.activeNavIndex === 3) {
            return;
          } else {
            this.activeNavIndex += 1;
          }
          this.changeToNext = false;
        }, 500);
      } else {
        this.changeToPrev = true;
        setTimeout(() => {
          if (this.activeNavIndex === 0) {
            return;
          } else {
            this.activeNavIndex -= 1;
          }
          this.changeToPrev = false;
        }, 500);
      }
    },
  },

  destroyed() {},
};
</script>

<style lang="scss" scoped>
/* 定义动画细节 */
@keyframes rotateNext {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}
@keyframes rotatePrev {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
@keyframes center_to_top {
  0% {
    top: 13.7rem;
    left: -7.75rem;
    width: 7.75rem;
    height: 2.81rem;
  }
  35% {
    top: 12rem;
    left: -6.05rem;
    width: 6.45rem;
    height: 2.1rem;
  }
  70% {
    top: 10.3rem;
    left: -4.35rem;
    width: 5.05rem;
    height: 1.41rem;
  }
  100% {
    top: 8.65rem;
    left: -2.64rem;
    width: 3.75rem;
    height: 0.88rem;
  }
}
@keyframes top_to_center {
  0% {
    top: 8.65rem;
    left: -2.64rem;
    width: 3.75rem;
    height: 0.88rem;
  }
  35% {
    top: 10.3rem;
    left: -4.35rem;
    width: 5.05rem;
    height: 1.41rem;
  }
  75% {
    top: 12rem;
    left: -6.05rem;
    width: 6.45rem;
    height: 2.1rem;
  }
  100% {
    top: 13.7rem;
    left: -7.75rem;
    width: 7.75rem;
    height: 2.81rem;
  }
}
@keyframes top_to_right {
  0% {
    top: 8.65rem;
    left: -2.64rem;
    width: 3.75rem;
    height: 0.88rem;
  }
  35% {
    top: 5.3rem;
    left: 1.65rem;
    width: 2.55rem;
    height: 0.58rem;
  }
  75% {
    top: 2rem;
    left: 5.05rem;
    width: 1.35rem;
    height: 0.28rem;
  }
  100% {
    top: 0rem;
    left: 7.5rem;
    width: 0rem;
    height: 0rem;
  }
}
@keyframes center_to_bottom {
  0% {
    top: 13.7rem;
    left: -7.75rem;
    width: 7.75rem;
    height: 2.81rem;
  }
  35% {
    top: 16rem;
    left: -6.05rem;
    width: 6.45rem;
    height: 2.1rem;
  }
  70% {
    top: 18.55rem;
    left: -4.35rem;
    width: 5.05rem;
    height: 1.41rem;
  }
  100% {
    top: 20.75rem;
    left: -2.64rem;
    width: 3.75rem;
    height: 0.88rem;
  }
}
@keyframes bottom_to_center {
  0% {
    top: 20.75rem;
    left: -2.64rem;
    width: 3.75rem;
    height: 0.88rem;
  }
  35% {
    top: 18.55rem;
    left: -4.35rem;
    width: 5.05rem;
    height: 1.41rem;
  }
  70% {
    top: 16rem;
    left: -6.05rem;
    width: 6.45rem;
    height: 2.1rem;
  }
  100% {
    top: 13.7rem;
    left: -7.75rem;
    width: 7.75rem;
    height: 2.81rem;
  }
}
@keyframes bottom_to_right {
  0% {
    top: 20.75rem;
    left: -2.64rem;
    width: 3.75rem;
    height: 0.88rem;
  }
  35% {
    top: 23.55rem;
    left: 0.95rem;
    width: 2.5rem;
    height: 0.58rem;
  }
  70% {
    top: 27rem;
    left: 4.75rem;
    width: 1.25rem;
    height: 0.28rem;
  }
  100% {
    top: 30rem;
    left: 7.75rem;
    width: 0rem;
    height: 0rem;
  }
}
.turntable {
  width: 14rem;
  height: 30rem;
  background-image: url("../assets/pc/home/big-turntable.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  position: fixed;
  right: -7rem;
  top: 50%;
  margin-top: -15rem;
  z-index: 99;
  &-center {
    box-sizing: border-box;
    width: 7rem;
    height: 7rem;
    background-image: url("../assets/pc/home/center-turntable.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -3.5rem;
    margin-top: -3.5rem;
  }
  .c_prev {
    animation: rotateNext 0.5s ease-in-out;
  }
  .c_next {
    animation: rotatePrev 0.5s ease-in-out;
  }
  &-index {
    width: 2.75rem;
    height: 2.75rem;
    background-image: url("../assets/pc/home/first-index.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    position: absolute;
    left: 0.3rem;
    top: 50%;
    margin-top: -1.375rem;
  }
  .top-nav {
    width: 3.75rem;
    height: 0.88rem;
    position: absolute;
    top: 8.65rem;
    left: -2.64rem;
    background-image: url("../assets/pc/home/video.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 0;
    cursor: pointer;
    // animation: top_to_center 0.5s linear;
  }
  .t_prev {
    // animation: rotateNext 1s linear;
    animation: top_to_center 0.5s linear;
  }
  .t_next {
    animation: top_to_right 0.5s linear;
  }
  .center-nav {
    width: 7.75rem;
    height: 2.81rem;
    position: absolute;
    top: 13.7rem;
    left: -7.75rem;
    background-image: url("../assets/pc/home/active-home.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 0;
    cursor: pointer;
  }
  .next {
    animation: center_to_top 0.5s linear;
  }
  .prev {
    animation: center_to_bottom 0.5s linear;
  }
  .bottom-nav {
    width: 3.75rem;
    height: 0.88rem;
    position: absolute;
    bottom: 8.25rem;
    left: -2.64rem;
    background-image: url("../assets/pc/home/gift.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 0;
    cursor: pointer;
  }
  .b_next {
    animation: bottom_to_center 0.5s linear;
  }
  .b_prev {
    animation: bottom_to_right 0.5s linear;
  }
}
</style>
