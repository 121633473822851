import Vue from "vue";
import Vuex from "vuex";
import languages from "../language";
import msgBox from "../components/msgbox/msgbox";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    language: "en",
    languages,
    msg: {},
    t: null,
    deviceType: "pc",
    refreshPage: null,
    title: "ヒント",
  },
  mutations: {
    SET_LANGUAGE: (state, language) => {
      state.language = language;
    },

    SET_DEVICE_TYPE: (state, str) => {
      state.deviceType = str;
    },
    SET_REFRESH_PAGE: (state, status) => {
      state.refreshPage = status;
    },
    openNewMsg(state, msgObj) {
      if (state.msg && state.msg.autoClose) {
        state.msg.closeMsg();
        if (state.t) {
          clearTimeout(state.t);
        }
      }
      state.msg = new msgBox({
        ...msgObj,
        visible: false,
      });
      state.msg.autoClose = msgObj.autoClose;
      state.msg.openMsg();
      if (msgObj.autoClose) {
        state.t = setTimeout(() => {
          state.msg.closeMsg();
          state.msg.autoClose = false;
          state.t = null;
        }, msgObj.time || 6500);
      }
    },
  },
  actions: {
    changeLanguages({ commit }, language) {
      return new Promise((resolve) => {
        localStorage.setItem("l", window.btoa(language));
        commit("SET_LANGUAGE", language);
        resolve();
      });
    },
  },
  modules: {},
});
