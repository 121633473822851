var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"content",style:({
      'background-image': 'url(' + _vm.homeInfo.bg + ')',
      'background-size': 'cover',
      'background-repeat': 'no-repeat',
      'background-position': 'center center',
    })},[_c('div',{staticClass:"content-area",on:{"mousemove":_vm.countShowEachHero,"mouseleave":_vm.cancelShowEachHero}},_vm._l((_vm.heroInfoList),function(item,i){return _c('div',{key:i,class:_vm.countHeroClassName(i),on:{"click":function($event){return _vm.showCurrentHeroInfo(item.name)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeHeroIndex[i]),expression:"activeHeroIndex[i]"}],attrs:{"src":item.hero_img,"alt":""}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.activeHeroIndex[i]),expression:"!activeHeroIndex[i]"}],staticClass:"default",style:({
            'background-image': 'url(' + item.hero_def_img + ')',
            'background-size': 'cover',
            'background-repeat': 'no-repeat',
            'background-position': 'center center',
          })}),_c('div',{class:("name name_" + (i + 1)),style:({
            'background-image': 'url(' + item.hero_name + ')',
          })})])}),0),(_vm.showFooter)?_c('div',{staticClass:"content-bot"}):_vm._e()]),_c('HeroInfoModal',{ref:"heroInfoModal",on:{"closeModal":_vm.closeModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }