<template>
  <div class="modal" v-if="showHeroInfo">
    <div class="modal-content">
      <div class="modal-content-back" @click="showHeroInfo = false"></div>
      <div class="modal-content-center">
        <div
          class="modal-content-center-hero"
          :style="{
            'background-image':
              'url(' + avatarList.portraitArr[2].heroInfo_img + ')',
            width: avatarList.portraitArr[2].width,
            height: avatarList.portraitArr[2].height,
            left: avatarList.portraitArr[2].left || '0',
          }"
        ></div>
      </div>
      <div class="modal-content-avatar">
        <div class="left" @click="changeActiveAvatar('prev')"></div>
        <div class="center">
          <div
            v-for="(item, i) in avatarList.portraitArr"
            :key="i"
            :style="{
              'background-image':
                'url(' + (i === 2 ? item.active_img : item.inactive_img) + ')',
            }"
            :class="i === 2 ? 'active-portrait' : 'inactive-portrait'"
            @click="showCurrentHeroInfo(item.name)"
            @mouseover="mouseHoverName = item.name"
          ></div>
        </div>
        <div class="right" @click="changeActiveAvatar('next')"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "component_name",
  data() {
    return {
      showHeroInfo: false,
      initStartIndex: 0,
      active_name: "balun",
      mouseHoverName: "",
      initPortraitNameList: ["meier", "shala", "balun", "xiunv", "luyisi"],
    };
  },
  watch: {
    showHeroInfo: {
      handler(val) {
        if (!val) {
          this.$emit("closeModal");
        }
      },
    },
  },
  created() {},
  computed: {
    avatarList() {
      let arr = [
        {
          active_img: require("@/assets/pc/hero/switch-avatar/active-portrait/meier.png"),
          hover_img: require("@/assets/pc/hero/switch-avatar/hover-portrait/meier_hover.png"),
          inactive_img: require("@/assets/pc/hero/switch-avatar/meier.png"),
          heroInfo_img: require("@/assets/pc/hero/hero-introduce/meier.png"),
          name: "meier",
          width: "118.56%",
          height: "168.93%",
        },
        {
          active_img: require("@/assets/pc/hero/switch-avatar/active-portrait/shala.png"),
          hover_img: require("@/assets/pc/hero/switch-avatar/hover-portrait/shala_hover.png"),
          inactive_img: require("@/assets/pc/hero/switch-avatar/shala.png"),
          heroInfo_img: require("@/assets/pc/hero/hero-introduce/shala.png"),
          name: "shala",
          width: "118.56%",
          height: "168.93%",
        },
        {
          active_img: require("@/assets/pc/hero/switch-avatar/active-portrait/balun.png"),
          hover_img: require("@/assets/pc/hero/switch-avatar/hover-portrait/balun_hover.png"),
          inactive_img: require("@/assets/pc/hero/switch-avatar/balun.png"),
          heroInfo_img: require("@/assets/pc/hero/hero-introduce/balun.png"),
          name: "balun",
          width: "96.56%",
          height: "160.93%",
          left: "7%",
        },
        {
          active_img: require("@/assets/pc/hero/switch-avatar/active-portrait/xiunv.png"),
          hover_img: require("@/assets/pc/hero/switch-avatar/hover-portrait/xiunv_hover.png"),
          inactive_img: require("@/assets/pc/hero/switch-avatar/xiunv.png"),
          heroInfo_img: require("@/assets/pc/hero/hero-introduce/xiunv.png"),
          name: "xiunv",
          width: "118.56%",
          height: "168.93%",
        },
        {
          active_img: require("@/assets/pc/hero/switch-avatar/active-portrait/luyisi.png"),
          hover_img: require("@/assets/pc/hero/switch-avatar/hover-portrait/luyisi_hover.png"),
          inactive_img: require("@/assets/pc/hero/switch-avatar/luyisi.png"),
          heroInfo_img: require("@/assets/pc/hero/hero-introduce/luyisi.png"),
          name: "luyisi",
          width: "118.56%",
          height: "168.93%",
        },
      ];
      let midIndex = arr.findIndex((item) => item.name === this.active_name);

      if (midIndex === -1) return null;
      let start = [...arr.slice(0, midIndex)];
      let end = [...arr.slice(midIndex + 1)];
      if (start.length == 0) {
        start = end.slice(-2);
        end = end.slice(0, 2);
      } else if (start.length == 1) {
        start = [...end.slice(-1), ...start];
        end = end.slice(0, 2);
      } else if (start.length == 3) {
        end = [...end, ...start.slice(0, 1)];
        start = start.slice(-2);
      } else if (start.length == 4) {
        end = [...start.slice(0, 2)];
        start = start.slice(-2);
      }
      const newArr = [
        ...start,
        // 第一部分
        arr[midIndex], // 中间数 // 第二部分
        ...end,
      ];
      console.log(123, newArr);
      return {
        portraitArr: newArr,
      };
    },
  },
  mounted() {},

  methods: {
    changeActiveAvatar(direction) {
      let index = this.initPortraitNameList.indexOf(this.active_name);
      if (direction == "prev") {
        if (!index) {
          index = 4;
        } else {
          index -= 1;
        }
      } else {
        if (index === 4) {
          index = 0;
        } else {
          index += 1;
        }
      }
      this.active_name = this.initPortraitNameList[index];
    },

    showModal(n) {
      this.showHeroInfo = true;
      console.log(12311, n);
      this.active_name = n;
    },
    showCurrentHeroInfo(name) {
      console.log(12322222, name);
      this.active_name = name;
      this.showDetail = true;
    },
    mouseOverHero(name) {},
  },

  destroyed() {},
};
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #040404ed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  &-content {
    // width: 78.44rem;
    // height: 35.19rem;
    width: 65.37%;
    height: 53.56%;
    position: absolute;
    top: 28.43%;
    left: 15.42%;
    background-image: url("../assets/pc/hero/hero-introduce/bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    &-back {
      width: 8.2rem;
      height: 3.75rem;
      position: absolute;
      top: -4.05rem;
      left: 0.7rem;
      background-image: url("../assets/pc/hero/back.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 999;
    }
    &-center {
      // width: 76.25rem;
      // height: 33rem;
      width: calc(100% - 2.12rem);
      height: calc(100% - 2.26rem);
      position: absolute;
      top: 1.13rem;
      left: 1.06rem;
      background-image: url("../assets/pc/hero/hero-introduce/border.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      &-hero {
        // width: 77.44rem;
        // height: 44.2rem;

        position: absolute;
        bottom: 0.8rem;
        left: 0%;
        // background-image: url("../assets/pc/hero/hero-introduce/shala.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    &-avatar {
      width: 46.8rem;
      height: 10.2rem;
      position: absolute;
      bottom: -11.25rem;
      left: 50%;
      margin-left: -23.4rem;
      // background-image: url("../assets/pc/hero/back.png");
      // background-size: 100% 100%;
      // background-repeat: no-repeat;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .left,
      .right {
        width: 2.6rem;
        height: 3.6rem;
        background-image: url("../assets/pc/hero/left-arrow.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .right {
        transform: rotate(180deg);
      }
      .center {
        width: calc(100% - 4rem);
        height: 10.2rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        div {
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center center;
        }
        .active-portrait {
          width: 8.56rem;
          height: 10.19rem;
          position: relative;
          z-index: 99;
          bottom: 1rem;
          // &::after {
          //   position: absolute;
          //   top: -35%;
          //   left: -10%;
          //   width: 122%;
          //   height: 145%;
          //   content: "";
          //   background-image: url("../assets/pc/hero/switch-avatar/active-bg.png");
          //   background-size: 100% 100%;
          //   background-repeat: no-repeat;
          //   z-index: 9;
          // }
        }
        .inactive-portrait {
          width: 6rem;
          height: 6rem;
        }
      }
    }
  }
}
</style>
