<template>
  <div class="home">
    <div
      class="content"
      :style="{
        'background-image': 'url(' + homeInfo.bg + ')',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': 'center center',
      }"
    >
      <div class="content-area">
        <div class="left-nav">
          <div class="left-nav-login" @click="handelLogin"></div>
          <div class="left-nav-box">
            <div v-for="(item, i) in leftNavList" :key="i" class="channel">
              <div
                class="icon"
                :style="{
                  'background-image':
                    'url(' +
                    (activeNavList[i] ? item.active_bg : item.inactive_bg) +
                    ')',
                }"
                @click="jumpLink(item.name)"
                @mouseover="handelTouchIcon('cover', i)"
                @mouseleave="handelTouchIcon('leave', i)"
              ></div>
            </div>
          </div>
        </div>
        <div class="regis-area">
          <!-- <img src="@/assets/pc/home/center-icon.png" alt="" /> -->
          <div class="center-icon"></div>
          <div class="center-regis">
            <div class="input-box">
              <input
                type="text"
                class="input"
                placeholder="E-mail"
                v-model="email"
                ref="emailInput"
              />
            </div>
            <div class="description"></div>

            <div class="protocol">
              <label>
                <input
                  type="checkbox"
                  class="checkbox"
                  name="fruits[]"
                  v-model="choice"
                  checked
                />
                <img src="@/assets/pc/home/regis/agree.png" alt="" />
              </label>
            </div>
            <div class="register-btn" @click="handelRegister"></div>
            <div class="download-btn">
              <div class="ios" @click="openLink('ios')"></div>
              <div class="android" @click="openLink('android')"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-logo"></div>
    </div>
    <Register
      ref="register"
      @changeRightNav="changeRightNav"
      @hiddenBottomDirIcon="closeDirection"
    />
  </div>
</template>

<script>
import { trackClick } from "@/tools/trackClick.js";
import { debounce } from "@/tools/debounce.js";
import { mapMutations, mapState } from "vuex";

import Register from "@/components/Register";
export default {
  name: "component_name",
  components: {
    Register,
  },
  data() {
    return {
      activeNavList: [false, false, false, false, false],
      cover: true,
      show: false,
      choice: false,
      email: "",
    };
  },
  inject: ["deviceType"],
  created() {},
  computed: {
    ...mapState(["msg", "title"]),
    homeInfo() {
      return {
        bg: require("../../assets/pc/home/bg.png"),
        name: "home",
      };
    },
    leftNavList() {
      return [
        {
          inactive_bg: require("@/assets/pc/home/twitter-inactive.png"),
          active_bg: require("@/assets/pc/home/twitter.png"),
          name: "twitter",
        },
        {
          inactive_bg: require("@/assets/pc/home/line-inactive.png"),
          active_bg: require("@/assets/pc/home/line.png"),
          name: "line",
        },
        {
          inactive_bg: require("@/assets/pc/home/youtube-inactive.png"),
          active_bg: require("@/assets/pc/home/youtube.png"),
          name: "youtube",
        },
        {
          inactive_bg: require("@/assets/pc/home/google-inactive.png"),
          active_bg: require("@/assets/pc/home/google.png"),
          name: "google",
        },
        {
          inactive_bg: require("@/assets/pc/home/ip-inactive.png"),
          active_bg: require("@/assets/pc/home/ip.png"),
          name: "ios",
        },
      ];
    },
  },
  mounted() {},

  methods: {
    ...mapMutations(["openNewMsg"]),
    handelLogin() {
      this.$refs.emailInput.focus();
    },
    changeRightNav(v) {
      this.$emit("changeRightNav", v);
    },
    handelTouchIcon(way, i) {
      this.activeNavList = this.activeNavList.map((item, ind) => {
        if (ind === i) {
          item = way == "cover" ? true : false;
        }
        return item;
      });
    },
    jumpLink(way) {
      let urlObj = {
        ios: {
          link: "https://apps.apple.com/jp/app/nations-of-darkness/id6469768494",
          track_name: "Click Ios store",
        },
        google: {
          link: "https://play.google.com/store/apps/details?id=com.allstarunion.nod.jp",
          track_name: "Click Google store",
        },
        twitter: {
          link: "https://twitter.com/NoDgameJP",
          track_name: "Click Twitter",
        },
        // wait
        youtube: {
          link: "https://www.youtube.com/@NoDgames",
          track_name: "Click Youtube",
        },
        // wait
        line: {
          link: "https://lin.ee/J9fiO9G",
          track_name: "Click Line",
        },
      };
      let url = urlObj[way].link;
      window.open(url, "_blank");
      // window.open(url, "_blank");
      trackClick({
        behavior_tag: urlObj[way].track_name,
      });
    },
    handelRegister: debounce(function () {
      const _this = this;
      const title = _this.title;
      if (!this.choice) {
        this.openNewMsg({
          title,
          body: "まずプロトコルをチェックしてください",
          autoClose: true,
          time: 1000,
        });
      } else {
        let reg =
          /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;
        if (!reg.test(this.email)) {
          this.openNewMsg({
            title,
            body: "メールフォーマットエラー",
            autoClose: true,
            time: 1000,
          });
        } else {
          trackClick({
            email: this.email,
            behavior_tag: "Submit Email",
          });
          // this.openNewMsg({
          //   title,
          //   body: "予約が成功しました!",
          //   autoClose: true,
          //   time: 1000,
          // });
          this.$refs.register.showModal();
          this.changeRightNav(false);
          this.$emit("hiddenBottomDirIcon", false);
          this.choice = false;
        }
        this.email = "";
      }
    }, 600),
    closeDirection(v) {
      console.log(124124, v);
      this.$emit("hiddenBottomDirIcon", v);
    },
    openLink(way) {
      if (way === "ios") {
        trackClick({
          behavior_tag: "Download Ios package",
        });
        window.open(
          "https://apps.apple.com/jp/app/nations-of-darkness/id6469768494",
          "_blank"
        );
      } else {
        trackClick({
          behavior_tag: "Download Android package",
        });
        window.open(
          "https://play.google.com/store/apps/details?id=com.allstarunion.nod.jp",
          "_blank"
        );
      }
    },
  },

  destroyed() {},
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .content {
    width: 100%;
    height: 100%;
    &-area {
      width: 100%;
      height: 100%;
      position: relative;
      .left-nav {
        width: 8.94rem;
        height: 46.94rem;
        background-image: url("../../assets/pc/home/left-nav.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 0 0;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        position: relative;
        &-login {
          width: 100%;
          height: 8.2rem;
          background-image: url("../../assets/pc/home/login-btn.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: 0 0;
          position: absolute;
          top: -1.7rem;
          cursor: pointer;
        }
        &-box {
          width: 100%;
          height: calc(100% - 8.2rem);
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: absolute;
          top: 8.2rem;
          .channel {
            width: 100%;
            height: calc(100% / 5);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            &::after {
              position: absolute;
              bottom: 0;
              left: 50%;
              margin-left: -2.35rem;
              content: "";
              width: 4.7rem;
              height: 0.13rem;
              background-image: url("../../assets/pc/home/device-line.png");
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center center;
            }
            .icon {
              width: 2.5rem;
              height: 2.5rem;
              background-repeat: no-repeat;
              background-size: contain;
              background-position: 0 0;
              cursor: pointer;
            }
          }
        }
      }
      .regis-area {
        width: 40.25%;
        height: auto;
        position: absolute;
        left: 9.21%;
        top: 10.81%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        .center-icon {
          width: 30.1rem;
          height: 18.43rem;
          background-image: url("../../assets/pc/home/center-icon.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center;
        }
        img {
          width: 41.1rem;
          height: 18.43rem;
        }
        .center-regis {
          width: 100%;
          height: 28.81rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          background-image: url("~@/assets/pc/home/regis/bg.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center center;
          padding-top: 2.45rem;
          margin-top: -2rem;
          .input-box {
            width: calc(100% - 13.76rem);
            height: 4.8rem;
            background: RGBA(5, 9, 17, 1);
            border: 1px solid rgba(99, 111, 127, 1);
            position: relative;
            .input {
              position: absolute;
              top: 1.4rem;
              left: 1.25rem;
              width: calc(100% - 2.5rem);
              height: calc(100% - 2.8rem);
              background-color: transparent;
              border: none;
              color: #d6d7d9;
              font-size: 1.8rem;
              &::placeholder {
                color: #393d44;
                font-size: 1.8rem;
                line-height: normal;
              }
              &:focus {
                outline: none;
              }
            }
          }
          .description {
            width: calc(100% - 13.76rem);
            height: 3.5rem;
            background-image: url("~@/assets/pc/home/regis/desc.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: left center;
            margin-top: 1rem;
          }
          .protocol {
            width: calc(100% - 13.76rem);
            margin: 0rem 0;
            height: 1.2rem;
            margin: 1.1rem 0;
            margin-top: 4.5rem;

            label {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
            }

            /* 外部容器 */
            .checkbox {
              position: relative;
              display: inline-block;
              width: 0.9rem;
              height: 0.9rem;
              margin-right: 1rem;
              cursor: pointer;
            }

            /* 复选框图标 */
            .checkbox {
              &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 0.9rem;
                height: 0.9rem;
                background-color: #21201e;
                border: 1px solid #f2f2f2;
              }
            }

            /* 选中状态 */
            .checkbox:checked {
              &::before {
                background-color: #8e9198;
                content: "\2713"; /* 引用 Unicode 符号 */
                color: #f2f2f2;
                font-size: 0.8rem;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            img {
              width: calc(100% - 1rem);
              height: 2.9rem;
              margin-left: -12rem;
            }
          }

          .register-btn {
            width: 21.31rem;
            height: 4.56rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url("~@/assets/pc/home/regis/btn.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center center;
            margin-top: 1.25rem;
            cursor: pointer;
            &:hover {
              background-image: url("~@/assets/pc/home/regis/btn_hover.png") !important;
            }
          }
          .download-btn {
            width: 21.31rem;
            height: 3.38rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 0.75rem;
            .ios {
              width: 10rem;
              height: 100%;
              background-image: url("~@/assets/pc/home/regis/IOS.png");
              background-repeat: no-repeat;
              background-size: 100% 100%;
              background-position: center center;
              cursor: pointer;
            }
            .android {
              width: 10rem;
              height: 100%;
              background-image: url("~@/assets/pc/home/regis/Android.png");
              background-repeat: no-repeat;
              background-size: 100% 100%;
              background-position: center center;
              cursor: pointer;
            }
          }
        }
      }
    }
    &-logo {
      width: 17.25rem;
      height: 6.69rem;
      background-image: url("~@/assets/pc/home/logo_ja.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      position: absolute;
      right: 3.58%;
      top: 6.3%;
    }
  }
}
</style>
