const fr = {
  label: {
    language: "Français",
    appoint_title: "Pré-inscrisez-vous et réclamez une tonne de récompenses",
    email_tips: "Bonus exclusif prour l`email associé",
    ios_appoint: "Pré-inscrire maintenant iOS",
    Public_beta: "Test ouvert iOS en octobre 2022",
    submit: "Soumettre",
    Reached: "Achevé",
    cur_appoint: "XX personnes ont été préinscrites",
    appoint_reward: "Récompenses de la pré-inscription ",
    page_title: 'Site Pré-inscription iOS "Myths of Moonrise"',
    appoint_now: "Préinscrire",
  },
  msg: {
    title: "Attention",
    success: "La pré-inscription achevé, veuillez patienter l`ouverture du jeu",
    has_appoint: "Vous avez déjà soumis la pré-inscription ",
    know: "OK",
    submit_success: "Soumis",
    email_error: "Saisir la correcte adresse E-mail",
    input_email: "Adresse E-mail",
  },
  gift: {
    food: "5 000 Aliment",
    wood: "5 000 Bois",
    common_recruitment_currency: "Monnaie d`Enrôlement Standard",
    diamond_supply_box: "Boîte de diamants",
    building_acceleration: "Accélérer Construction 5 min",
    accelerated_scientific_research: "Accélérer Recherches 5 min",
    training_acceleration: "Accélérer Entraînement 5 min",
    manufacturing_acceleration: "Accélérer Pièges 5 min",
    advanced_recruitment_currency: "Monnaie d`Enrôlement Avancé",
    fortifying_medicament: "1 000 Sirop",
    wizard_Jenny: "Jenny (Mage 3★)",
    witch_Matt: "Matt (Mage 4★)",
  },
  footer: {
    $1: "Politique de confidentialité",
    $2: "Accord d'utilisateur",
    $3: "Sichuan Public Network Security No. 51019002003535",
    $4: "Shu ICP No. 2020036928-1",
    $5: "Copyright @2019-2022 Tous droits réservés",
  },
};
export default fr;
