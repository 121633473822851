const ru = {
  label: {
    language: "русский язык",
    appoint_title: "Получите награду за предзаказ",
    email_tips: "Введите свой e-mail и получите эксклюзивный приз!",
    ios_appoint: "Совершить предзаказ для iOS",
    Public_beta: "Открытый тест для iOS - Октябрь 2022",
    submit: "Записаться",
    Reached: "Успешно",
    cur_appoint: "XX человек уже сделали предзаказ",
    appoint_reward: "Награда за предзаказ",
    page_title: "Предзаказ Myths of Moonrise на iOS",
    appoint_now: "Совершить предзаказ",
  },
  msg: {
    title: "Сообщение",
    success: "Предзаказ успешен, ожидайте оповещения о запуске",
    has_appoint: "Заявка уже подана, невозможно записаться повторно",
    know: "Принять",
    submit_success: "Отправлено",
    email_error: "E-mail введен неправильно, попробуйте снова",
    input_email: "Ваш e-mail",
  },
  gift: {
    food: "5000 Еды",
    wood: "5000 Дерева",
    common_recruitment_currency: "Монета Базового Найма",
    diamond_supply_box: "Алмазный Ящик",
    building_acceleration: "Ускоритель Строительства (5 мин)",
    accelerated_scientific_research: "Ускоритель Исследования (5 мин)",
    training_acceleration: "Ускоритель Обучения (5 мин)",
    manufacturing_acceleration: "Ускор. Произв-во Укреп. (5 мин.)",
    advanced_recruitment_currency: "Монета Отличного Найма",
    fortifying_medicament: "1000 Микстуры",
    wizard_Jenny: "3★ Колдун - Дженни",
    witch_Matt: "4★ Колдун - Мэтт",
  },
  footer: {
    $1: "Политика Конфиденциальности",
    $2: "Условия Обслуживания",
    $3: "川公网安备 51019002003535号",
    $4: "蜀ICP备2020036928号-1",
    $5: "Авторские права @2019-2022 Все права защищены",
  },
};
export default ru;
