<template>
  <div class="home">
    <div class="content">
      <div class="content-register" @click="handelRegister"></div>
      <div class="content-people">
        <div class="content-people-top">
          <span>{{ addCommas }}</span>
          <img src="@/assets/mobile/home/attach-top.png" alt="" />
        </div>
        <div class="content-people-bottom"></div>
        <img
          src="@/assets/mobile/home/question_mark.png"
          alt=""
          class="mask-icon"
          @click="showMaskModal"
        />
      </div>
      <div class="content-award">
        <div class="area">
          <div class="area-img">
            <img src="@/assets/mobile/reward/5w_reward.png" alt="" />
            <img src="@/assets/mobile/reward/10w_reward.png" alt="" />
            <img src="@/assets/mobile/reward/20w_reward.png" alt="" />
            <img src="@/assets/mobile/reward/35w_reward.png" alt="" />
            <img src="@/assets/mobile/reward/50w_reward.png" alt="" />
          </div>
          <div class="area-line">
            <div
              v-for="(item, i) in 5"
              :key="i"
              :style="{
                left: `calc((10% * ${2 * (i + 1) - 1}))`,
              }"
              class="dot"
              :class="countReachNumDot"
            ></div>
            <div class="area-line-cover" :class="countReachNum"></div>
          </div>

          <div class="area-introduce">
            <img src="@/assets/mobile/reward/1D.png" alt="" />
            <img src="@/assets/mobile/reward/2D.png" alt="" />
            <img src="@/assets/mobile/reward/3D .png" alt="" />
            <img src="@/assets/mobile/reward/4D.png" alt="" />
            <img src="@/assets/mobile/reward/5D.png" alt="" />
          </div>
        </div>
      </div>
      <span class="right-arrow"> </span>
    </div>
    <MobileRegister ref="register" @hideRegister="hideRegister" />
    <div class="mask-modal" v-if="showDesModal">
      <!-- <img src="@/assets/mobile/home/reward_des.png" alt=""> -->
      <div class="mask-modal-center">
        <div class="mask-modal-center-close" @click="closeMaskModal"></div>
      </div>
    </div>
  </div>
</template>

<script>
import MobileRegister from "@/components/MobileRegister";
import { debounce } from "@/tools/debounce.js";
import { trackClick } from "@/tools/trackClick";
import { convertUTCTimeToLocalTime } from "@/tools/convertUTCTimeToLocalTime";
export default {
  name: "component_name",
  components: {
    MobileRegister,
  },
  data() {
    return {
      activePeopleNum: 0,
      peopleNumberObj: {
        0: { total: 89357, min: 4, max: 8 },
        1: { total: 170679, min: 3, max: 6 },
        2: { total: 241307, min: 3, max: 6 },
        3: { total: 280765, min: 1, max: 3 },
        4: { total: 321867, min: 1, max: 3 },
        5: { total: 369453, min: 1, max: 3 },
        6: { total: 405493, min: 1, max: 3 },
        7: { total: 450873, min: 1, max: 3 },
        8: { total: 514950, min: 1, max: 5 },
        9: { total: 584513, min: 3, max: 6 },
        10: { total: 633389, min: 1, max: 5 },
        11: { total: 699642, min: 1, max: 5 },
        12: { total: 765982, min: 1, max: 5 },
        13: { total: 804868, min: 1, max: 5 },
        14: { total: 886271, min: 3, max: 6 },
        15: { total: 936879, min: 3, max: 6 },
        16: { total: 1010067, min: 3, max: 6 },
        17: { total: 1030097, min: 3, max: 6 },
        18: { total: 1067854, min: 3, max: 6 },
        19: { total: 1098462, min: 3, max: 6 },
        20: { total: 1138726, min: 3, max: 6 },
        21: { total: 1179643, min: 3, max: 6 },
        22: { total: 1199873, min: 3, max: 6 },
        23: { total: 1227896, min: 3, max: 6 },
        24: { total: 1267843, min: 3, max: 6 },
        25: { total: 1293323, min: 3, max: 6 },
        26: { total: 1334211, min: 3, max: 6 },
        27: { total: 1376531, min: 3, max: 6 },
        28: { total: 1400356, min: 3, max: 6 },
        29: { total: 1453846, min: 3, max: 6 },
        30: { total: 1493847, min: 3, max: 6 },
      },
      showDesModal: false,
    };
  },
  created() {},
  computed: {
    addCommas() {
      //把num转换为字符串
      var numStr = this.activePeopleNum.toString();
      //获取字符串长度
      var len = numStr.length;
      //计算需要加多少个逗号
      var commasCount = Math.floor((len - 1) / 3);
      //循环添加逗号
      for (var i = 0; i < commasCount; i++) {
        var index = len - (i + 1) * 3;
        numStr = numStr.substring(0, index) + ", " + numStr.substring(index);
      }
      return numStr;
    },
    homeInfo() {
      return {
        bg: require("../../assets/mobile/home/bg.jpg"),
        name: "home",
      };
    },
    countReachNum() {
      if (this.isInRange(this.activePeopleNum, -1, 50000)) {
        return "line-null";
      } else if (this.isInRange(this.activePeopleNum, 50000, 100000)) {
        return "line-5";
      } else if (this.isInRange(this.activePeopleNum, 100000, 200000)) {
        return "line-10";
      } else if (this.isInRange(this.activePeopleNum, 200000, 350000)) {
        return "line-20";
      } else if (this.isInRange(this.activePeopleNum, 350000, 500000)) {
        return "line-35";
      } else {
        return "line-50";
      }
    },
    countReachNumDot() {
      if (this.isInRange(this.activePeopleNum, -1, 50000)) {
        return "line-null";
      } else if (this.isInRange(this.activePeopleNum, 50000, 100000)) {
        return "dot-5";
      } else if (this.isInRange(this.activePeopleNum, 100000, 200000)) {
        return "dot-10";
      } else if (this.isInRange(this.activePeopleNum, 200000, 350000)) {
        return "dot-20";
      } else if (this.isInRange(this.activePeopleNum, 350000, 500000)) {
        return "dot-35";
      } else {
        return "dot-50";
      }
    },
  },
  mounted() {
    let today = new Date();
    // 对当前系统时间的偏移量
    let offset = today.getTimezoneOffset() * 60000;
    let utcTime = today.getTime() + offset + 9 * 60 * 60000; // 日区 = utc+9h
    let curTime = new Date(utcTime).getTime();
    // 结束时间的utc时间，转为本地时区时间，在计算对应utc时间戳
    let endLocalTime = convertUTCTimeToLocalTime("2024-01-04T15:00:00.000Z");
    let endUtcTime =
      new Date(endLocalTime).getTime() +
      new Date(endLocalTime).getTimezoneOffset() * 60000 +
      9 * 60 * 60000;
    let endTime = new Date(endUtcTime).getTime();

    let curDay = 31 - Math.ceil((endTime - curTime) / (24 * 60 * 60000));
    let passTime =
      (new Date(utcTime).getHours() * 60 * 60 * 1000 +
        new Date(utcTime).getMinutes() * 60 * 1000 +
        new Date(utcTime).getSeconds() * 1000 +
        new Date(utcTime).getMilliseconds()) /
      10000;
    if (curDay >= 31) {
      this.timer = null;
      this.activePeopleNum = this.peopleNumberObj[30].total;
      return;
    } else if (curDay < 0) {
      this.timer = null;
      this.activePeopleNum = 0;
      return;
    }
    let { max, min } = this.peopleNumberObj[curDay];
    // 刷新，首次进入按照当天已过秒数根据增长曲线计算已有人数
    let hasGetTodayPeople = Math.round(passTime * (max + min));
    this.activePeopleNum =
      curDay == 0
        ? hasGetTodayPeople
        : this.peopleNumberObj[curDay - 1].total + hasGetTodayPeople;

    // 每日最后10s人数自动赋值当天目标最大值
    // 当天的23:59:50
    var todayEndFirstTime = new Date(
      new Date(new Date(utcTime).toLocaleDateString()).getTime() + 86390000
    ).getTime(); //毫秒
    // 第二天的00:00:00
    var todayEndLastTime = new Date(
      new Date(new Date(utcTime).toLocaleDateString()).getTime() + 86400000
    ).getTime(); //毫秒

    if (
      new Date(utcTime).getTime() >= todayEndFirstTime &&
      new Date(utcTime).getTime() <= todayEndLastTime
    ) {
      this.activePeopleNum = this.peopleNumberObj[curDay].total;
    }
  },

  methods: {
    showMaskModal() {
      this.showDesModal = true;
      this.hideRegister(false);
    },
    closeMaskModal() {
      this.showDesModal = false;
      this.hideRegister(true);
    },
    isInRange(value, min, max) {
      return value >= min && value < max;
    },
    handelRegister() {
      this.$refs.register.showModal();
      this.hideRegister(false);
    },
    hideRegister(value) {
      this.$emit("hideHeader", value);
    },
  },

  destroyed() {},
};
</script>

<style lang="scss" scoped>
@keyframes shake {
  from,
  to {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-2px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(2px);
  }
}
@keyframes shake_y {
  from,
  to {
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateY(-2px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateY(2px);
  }
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
    background-image: url("../../assets/mobile/home/active-point.png");
  }
}
@keyframes gradientAnimation_5 {
  0% {
    width: 0;
  }
  100% {
    width: 10%;
  }
}
@keyframes gradientAnimation_10 {
  0% {
    width: 0;
  }
  50% {
    width: 10%;
  }
  100% {
    width: 30%;
  }
}

@keyframes gradientAnimation_20 {
  0% {
    width: 0;
  }
  25% {
    width: 10%;
  }
  75% {
    width: 30%;
  }
  100% {
    width: 50%;
  }
}
@keyframes gradientAnimation_35 {
  0% {
    width: 0;
  }
  25% {
    width: 10%;
  }
  50% {
    width: 30%;
  }
  75% {
    width: 50%;
  }
  100% {
    width: 70%;
  }
}
@keyframes gradientAnimation_50 {
  0% {
    width: 0;
  }
  20% {
    width: 10%;
  }
  40% {
    width: 30%;
  }
  60% {
    width: 50%;
  }
  80% {
    width: 70%;
  }
  90% {
    width: 90%;
  }
  100% {
    width: 100%;
  }
}

.home {
  width: 100%;
  height: 135rem;
  // overflow: hidden;
  // position: absolute;
  // top: 0;
  .mask-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    background-image: url("../../assets/mobile/register/modal.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0rem 0;
    &-center {
      width: 100%;
      height: 45.75rem;
      background-image: url("../../assets/mobile/home/reward_des.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: 0rem 0;
      position: absolute;
      top: 50%;
      margin-top: -27.875rem;
      &-close {
        width: 5.75rem;
        height: 7.33rem;
        background-image: url("../../assets/mobile/register/close-btn.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center center;
        position: absolute;
        top: 0.75rem;
        cursor: pointer;
        right: 5.25rem;
      }
    }
  }
  .content {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 99;

    &-register {
      width: 29.1rem;
      height: 9rem;
      position: absolute;
      top: 56.75%;
      left: 50%;
      margin-left: -14.55rem;
      background-image: url("../../assets/mobile/home/register-btn.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
    }
    &-people {
      width: 48.83rem;
      height: 8.25rem;
      position: absolute;
      top: 66.3%;
      left: 50%;
      margin-left: -24.415rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      &-top {
        width: 100%;
        height: 4.6rem;
        display: flex;
        justify-content: center;
        align-items: baseline;
        img {
          width: 6rem;
          height: 2rem;
        }
        span {
          font-size: 3.5rem;
          background: linear-gradient(to bottom, #bdae7d, #d5c592);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-right: 0.5rem;
        }
      }
      &-bottom {
        width: 100%;
        height: 2.5rem;
        background-image: url("../../assets/mobile/home/attach-bt.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
      }
      .mask-icon {
        width: 4rem;
        height: 4rem;
        position: absolute;
        bottom: -5.5rem;
        right: -6.5rem;
        z-index: 99;
        animation-name: shake_y;
        animation-duration: 7s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
      }
    }
    &-award {
      width: 57.2rem;
      height: 28.15rem;
      position: absolute;
      // bottom: 4.5%;
      top: 76%;
      left: 50%;
      margin-left: -28.6rem;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }

      .area {
        width: 95.28rem;
        height: 100%;
        position: relative;
        &-img {
          width: calc(100% - 5rem);
          height: 17rem;
          display: flex;
          justify-content: space-between;
          position: absolute;
          top: 0;
          left: 2.5rem;
          img {
            width: 13.75rem;
            height: 17rem;
          }
        }
        &-line {
          width: 100%;
          height: 2px;
          background: linear-gradient(to right, #3e3c3e, #3e3c3e);
          position: absolute;
          top: 18.5rem;
          left: 0rem;
          .dot {
            width: 1.92rem;
            height: 1.7rem;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center center;
            position: absolute;
            top: 50%;
            margin-top: -0.85rem;
            z-index: 99999;
            background-image: url("../../assets/mobile/home/inactive-point.png");
          }
          .dot-5 {
            &:nth-child(-n + 1) {
              animation: scaleAnimation 0.5s ease-in-out 3s forwards;
              //   background-image: url("../../assets/mobile/home/active-point.png") !important;
            }
            &:not(:nth-child(-n + 1)) {
              background-image: url("../../assets/mobile/home/inactive-point.png") !important;
            }
          }
          .dot-10 {
            &:first-child {
              animation: scaleAnimation 0.5s ease-in-out 1.2s forwards !important;
            }
            &:nth-child(-n + 2) {
              animation: scaleAnimation 0.5s ease-in-out 3s forwards;
            }
            &:not(:nth-child(-n + 2)) {
              background-image: url("../../assets/mobile/home/inactive-point.png") !important;
            }
          }
          .dot-20 {
            &:first-child {
              animation: scaleAnimation 0.5s ease-in-out 0.8s forwards !important;
            }
            &:nth-child(2) {
              animation: scaleAnimation 0.5s ease-in-out 2s forwards !important;
            }
            &:nth-child(-n + 3) {
              animation: scaleAnimation 0.5s ease-in-out 3s forwards;
            }
            &:not(:nth-child(-n + 3)) {
              background-image: url("../../assets/mobile/home/inactive-point.png") !important;
            }
          }
          .dot-35 {
            &:first-child {
              animation: scaleAnimation 0.5s ease-in-out 0.8s forwards !important;
            }
            &:nth-child(2) {
              animation: scaleAnimation 0.5s ease-in-out 2s forwards !important;
            }
            &:nth-child(3) {
              animation: scaleAnimation 0.5s ease-in-out 3s forwards !important;
            }
            &:nth-child(-n + 4) {
              animation: scaleAnimation 0.5s ease-in-out 4s forwards;
            }
            &:not(:nth-child(-n + 4)) {
              background-image: url("../../assets/mobile/home/inactive-point.png") !important;
            }
          }
          .dot-50 {
            &:first-child {
              animation: scaleAnimation 0.5s ease-in-out 0.8s forwards !important;
            }
            &:nth-child(2) {
              animation: scaleAnimation 0.5s ease-in-out 2s forwards !important;
            }
            &:nth-child(3) {
              animation: scaleAnimation 0.5s ease-in-out 3s forwards !important;
            }
            &:nth-child(4) {
              animation: scaleAnimation 0.5s ease-in-out 4s forwards !important;
            }
            &:nth-child(5) {
              animation: scaleAnimation 0.5s ease-in-out 4.5s forwards !important;
            }
          }

          &-cover {
            // width: 100%;
            height: 1px;
            background: linear-gradient(
              to right,
              #181b23,
              #b0a193,
              #e2cdb8,
              #eccb8f
            );
            position: absolute;
            top: 0rem;
            left: 0rem;
            z-index: 999;
          }
        }

        .line-5 {
          animation: gradientAnimation_5 3s ease-in-out forwards;
        }
        .line-10 {
          animation: gradientAnimation_10 3s ease-in-out forwards;
        }
        .line-20 {
          animation: gradientAnimation_20 3s ease-in-out forwards;
        }
        .line-35 {
          animation: gradientAnimation_35 4s ease-in-out forwards;
        }
        .line-50 {
          animation: gradientAnimation_50 5s ease-in-out forwards;
        }
        &-introduce {
          width: 100%;
          height: 7.5rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          bottom: 0;
          img {
            width: 17.5rem;
            height: 100%;
          }
        }
      }
    }
    .right-arrow {
      width: 1.75rem;
      height: 2.33rem;
      background-image: url("../../assets/mobile/home/right-arrow.png") !important;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      right: 0.7rem;
      bottom: calc(4.5% + 8.5rem);
      margin-top: -1.165rem;
      z-index: 999;
      animation-name: shake;
      animation-duration: 7s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
  }
}
</style>
