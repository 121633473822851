<template>
  <div class="contain" ref="contain">
    <div class="contain-center">
      <Home />
      <Hero />
      <TheHeader v-if="showHeader" @setCurrentIndex="setSlideIndex" />
    </div>
    <!-- <img class="contain-bg" src="@/assets/mobile/bg.png" alt="" /> -->

    <FooterMobile />
  </div>
</template>
<script>
import "swiper/swiper-bundle.css";
import Swiper from "swiper/bundle";
import Home from "./Mobile/Home.vue";
// import Character from "./Mobile/character.vue";
import Video from "./Mobile/Video.vue";
import TheHeader from "@/components/TheHeader";
import Hero from "./Mobile/hero.vue";
import FooterMobile from "@/components/FooterMobile";
export default {
  components: {
    Home,
    // Gift,
    // Character,
    // Movie,
    TheHeader,
    Video,
    Hero,
    FooterMobile,
  },

  provide() {
    return {
      activeSlideObj: this.activeInfo,
    };
  },
  data() {
    return {
      mySwiper: null,
      activeInfo: {
        activeSlideIndex: 0,
      },
      showHeader: true,
    };
  },

  mounted() {
    const _this = this;

    this.mySwiper = new Swiper("#swiper-box", {
      speed: 800, // 切换轮播速度
      mousewheel: true, // 支持鼠标滚轮滚动
      direction: "vertical",
      loop: false, // 循环模式选项
      slidesPerView: "auto",
      // noSwiping: true,
      preventInteractionOnTransition: true,
      longSwipes: true,
      longSwipesRatio: 0.6, // 调整阀值（0~1）
      on: {
        init: function () {
          //Swiper初始化了
          _this.activeInfo.activeSlideIndex = this.activeIndex;
        },
        slideChange: function () {
          _this.activeInfo.activeSlideIndex = this.activeIndex;
        },
      },
    });
  },

  methods: {
    getWindowConfig() {
      let windowWidth = window.innerWidth;
      let windowHeight = window.innerHeight;
      if (typeof windowWidth !== "number") {
        if (document.compatMode === "CSS1Compat") {
          windowWidth = document.documentElement.clientWidth;
          windowHeight = document.documentElement.clientHeight;
        } else {
          windowWidth = document.body.clientWidth;
          windowHeight = window.body.clientHeight;
        }
      }
      return {
        windowWidth: windowWidth,
        windowHeight: windowHeight,
      };
    },

    setSlideIndex(i) {
      // this.mySwiper.slideTo(i, 1000, false);
      // this.activeInfo.activeSlideIndex = i;
      const container = this.$refs.contain;

      // 滚动到容器底部
      container.scrollTo({
        top: i ? container.scrollHeight : 0,
        behavior: "smooth", // 添加平滑滚动效果，如果需要
      });
    },
    jumpToHero(i) {
      this.mySwiper.slideTo(1, 1000, false);
      this.activeInfo.activeSlideIndex = 1;
    },
    hideHeader(v) {
      this.showHeader = v;
    },
  },
  beforeDestroy() {
    this.mySwiper = null;
  },
};
</script>
<style lang="scss" scope>
.contain {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  position: relative;
  overflow-y: auto;
  &-bg {
    width: 100%;
  }
  &-center {
    width: 100%;
    height: 208rem;
    background: url("~@/assets/mobile/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}
.swiper-container_m {
  width: 100%;
  height: 100vh;
}
.wiper-wrapper {
  width: 100%;
  height: 100%;
}
.m_each {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:first-child {
    width: 100%;
    height: 100%;
  }
  &:nth-child(2) {
    width: 100%;
    height: 100%;
  }
  &:nth-child(3) {
    width: 100%;
    height: 100%;
  }
}
</style>
