<template>
  <div class="hero">
    <img class="hero-title" src="@/assets/mobile/charter_title.png" alt="" />
    <div class="hero-content">
      <div
        class="bg"
        :style="{
          'background-image': 'url(' + heroInfoList[activeIndex].hero_img + ')',
          'background-size': 'contain',
          'background-repeat': 'no-repeat',
          'background-position': 'center center',
        }"
      ></div>
      <div class="carousel">
        <div
          class="carousel-item"
          :style="{
            'background-image':
              'url(' +
              (index === activeIndex ? item.head_active : item.head_default) +
              ')',
          }"
          v-for="(item, index) in heroInfoList"
          :key="index"
          @click="activeIndex = index"
        ></div>
      </div>
      <img
        class="left_arrow arrow"
        src="@/assets/mobile/left_arrow.png"
        alt=""
        @click="changeIndex('prev')"
      />
      <img
        class="right_arrow arrow"
        src="@/assets/mobile/right_arrow.png"
        alt=""
        @click="changeIndex('next')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "component_name",
  data() {
    return {
      activeIndex: 2,
    };
  },
  created() {},
  computed: {
    heroInfoList() {
      return [
        {
          hero_img: require("@/assets/mobile/1.png"),
          head_default: require("@/assets/mobile/1_head_default.png"),
          head_active: require("@/assets/mobile/1_head.png"),
        },
        {
          hero_img: require("@/assets/mobile/2.png"),
          head_default: require("@/assets/mobile/2_head_default.png"),
          head_active: require("@/assets/mobile/2_head.png"),
        },
        {
          hero_img: require("@/assets/mobile/3.png"),
          head_default: require("@/assets/mobile/3_head_default.png"),
          head_active: require("@/assets/mobile/3_head.png"),
        },
        {
          hero_img: require("@/assets/mobile/4.png"),
          head_default: require("@/assets/mobile/4_head_default.png"),
          head_active: require("@/assets/mobile/4_head.png"),
        },
        {
          hero_img: require("@/assets/mobile/5.png"),
          head_default: require("@/assets/mobile/5_head_default.png"),
          head_active: require("@/assets/mobile/5_head.png"),
        },
      ];
    },
  },
  mounted() {},

  methods: {
    changeIndex(type) {
      if (type === "next") {
        if (this.activeIndex === 4) {
          this.activeIndex = 0;
        } else {
          this.activeIndex++;
        }
      } else {
        if (this.activeIndex === 0) {
          this.activeIndex = 4;
        } else {
          this.activeIndex--;
        }
      }
    },
  },

  destroyed() {},
};
</script>

<style lang='scss' scoped>
.hero {
  width: 100%;
  height: calc(100% - 137rem);
  //   position: absolute;
  //   top: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
  &-title {
    // width: 25.56rem;
    // width: 54.52%;
    height: 6.83rem;
  }
  &-content {
    width: 100%;
    height: calc(100% - 7rem);
    position: relative;
    .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .carousel {
      width: calc(100% - 6rem);
      height: 8rem;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      position: absolute;
      bottom: 3.67rem;
      left: 50%;
      flex-direction: row;
      transform: translate(-50%, 0);
      &-item {
        width: calc((100% - 14.68rem) / 5);
        height: 100%;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
    .arrow {
      width: 1.5rem;
      height: auto;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }
    .left_arrow {
      left: 1.75rem;
    }
    .right_arrow {
      right: 1.75rem;
    }
  }
}
</style>